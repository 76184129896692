import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FormControl,
} from "react-bootstrap";
import { useParams ,useNavigate} from "react-router-dom";
import useUser from "../hooks/useUser";
 
const OrderPage = () => {
  const [customerName, setcustomerName] = useState();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [mapSrc, setMapSrc] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [serviceList, setServiceList] = useState("");
  const [subService, setSubService] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [serviceProvider, setServiceProvider] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProviders, setFilteredProviders] = useState([]);
  const { user, isLoading } = useUser();
  const [isSended, setIsSended] = useState(false);
  const { service_id } = useParams();
  const [serviceProviderChecked, setServiceProviderChecked] = useState([]);
  const [error, setError] = useState("");
  var serviceProviderCheckedArray = [];
  const navigate = useNavigate()

  const getServiceList = async () => {
    // Fetch service list from API based on service_id
    const token = await user.getIdToken();
    const headers = token ? { authtoken: token } : {};
    const resp = await axios.get(`/api/order/${service_id}`, { headers });
    setServiceList(resp.data.service);
    setServiceProvider(resp.data.service.ServiceProvider);
    //console.log(resp.data.service.ServiceProvider)
    setSubService(resp.data.service.ServiceList[0].SubService);
    setPaymentMethod("كاش");
    setCity("المدينة المنورة");
  };

  useEffect(() => {
    if (!isLoading) {
      getServiceList();
      setcustomerName(user.displayName);
      setEmail(user.email);
      setPhoneNumber(user.phoneNumber);
    }
  }, [service_id, isLoading]);

  const addOrderToDatabase = async (orderData) => {
    try {
      const token = await user.getIdToken();
        const response = await axios.post("/api/order/addorder", orderData, {
          headers: {
            "Content-Type": "application/json", // Example custom header
            authtoken: token, // Example authorization header
          },
        });
        setIsSended(true);
        if (response) 
          getServiceList();
          setTimeout(navigate('/servicelist'), 2000);

      
    } catch (error) {
      console.error("Error adding order to database:", error);
      throw error;
    }
  };

  const handleCitySelect = (selectedCity) => {
    setCity(selectedCity);
    switch (selectedCity) {
      case "المدية المنورة":
        setMapSrc(
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24985.540460477833!2d39.59782714999999!3d24.4667022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1501255747474749:0x92404868f5252962!2z2K_YsdinIFdvcmFuYQ!5e0!3m2!1sar!2sjo!4v1648229173491!5m2!1sar!2sjo"
        );
        break;
      case "مكة":
        setMapSrc(
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3495.1797023303843!2d39.817121014890245!3d21.422545585806692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c218a8cf46c5ff%3A0xe9471694e367fc5c!2z2LTYp9mH2K8g2KfYs9iq2LHZiNmC!5e0!3m2!1sen!2sjo!4v1648229465010!5m2!1sen!2sjo"
        );
        break;
      case "الرياض":
        setMapSrc(
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3862.9740577997047!2d46.75468741482259!3d24.71167618411305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f02d7fa0b1955%3A0x608aeeea6cd7a243!2z2KfZhNin2YUg2KfZhNi12LnZitiv2YbZitmD2Kkg2YjZhdit2K_Zhtiv!5e0!3m2!1sen!2sjo!4v1648229505310!5m2!1sen!2sjo"
        );
        break;
      case "القصيم":
        setMapSrc(
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3653.994580606956!2d43.99696451488704!3d26.30963838339646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x157f68d3730e1463%3A0x706e4729e8e18542!2z2KfZhNi02LnYqNio2KfYqiA!5e0!3m2!1sen!2sjo!4v1648229534897!5m2!1sen!2sjo"
        );
        break;
      default:
        setMapSrc("");
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filtered = serviceProvider.filter((provider) =>
      provider.Name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredProviders(filtered);
  };

  const handleCheckboxChange = (providerId) => {
    // Filter checked providers immediately after checkbox change
    const checkedProviders = serviceProvider.filter(
      (provider) => provider.checked
    );
    setServiceProviderChecked(checkedProviders);

    setServiceProvider(
      serviceProvider.map((provider) =>
        provider._id === providerId
          ? { ...provider, checked: !provider.checked }
          : provider
      )
    );
  };

  useEffect(() => {
    serviceProviderCheckedArray.push(
      serviceProvider.filter((provider) => provider.checked)
    );
    console.log(serviceProviderCheckedArray);
  }, [serviceProviderChecked]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const orderData = {
      customerName,
      email,
      phoneNumber,
      city,
      mapSrc,
      date,
      time,
      subService,
      paymentMethod,
      serviceProvider: serviceProviderCheckedArray,
      isReaddd: false,
      isConfirm: false,
      status: "جديدة",
      progress: 0,
      isReaded: false,
      messageStatus: false,
      cusConfirmation: false,
      isUserReadConfirm: false,
      confirmedBy: "",
      messages: [],
      companyAccept: [],
      companyImplemented: {},
      CompanyComplete:false,
      custConfComplete:false,
    };

    try {
      if (serviceProviderCheckedArray.length === 0) {
        setError("الرجاء اختيار احد مزود الخدمة اولا");
      } else {
      addOrderToDatabase(orderData);
      setcustomerName("");
      setEmail("");
      setPhoneNumber("");
      setCity("");
      setMapSrc("");
      setDate("");
      setTime("");
      setSubService("");
      setPaymentMethod("");
      setServiceProvider([]);
      }
    } catch (error) {
      console.error("Error submitting order:", error);
      // يمكنك إضافة معالجة للأخطاء هنا إذا كنت بحاجة إليها
    }
  };

  return (
    <Container>
      {isSended ? (
        <div
          className="alert alert-success d-flex align-items-center"
          role="alert"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-check-circle-fill me-2"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0ZM6.5 11a.5.5 0 0 0 .354.146l.646-.647a.5.5 0 1 1 .708.708l-1 1a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708l1.646 1.647Zm5.792-7.5a.5.5 0 0 0-.708-.708l-5 5a.5.5 0 0 0 .708.708l5-5a.5.5 0 0 0 0-.708Z"
            />
          </svg>
          تم إرسال طلب الخدمة بنجاح سيتم التواصل معك بواسطة مزود الخدمة الذي
          اخترته
        </div>
      ) : (
        <div></div>
      )}

      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col lg={3} md={6} xs={12}>
            <Form.Group as={Col} controlId="customerName">
              <Form.Label>اسم مقدم الطلب</Form.Label>
              <Form.Control
                type="text"
                placeholder="أدخل اسمك"
                value={customerName}
                onChange={(e) => setcustomerName(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col lg={3} md={6} xs={12}>
            <Form.Group as={Col} controlId="email">
              <Form.Label>البريد الإلكتروني</Form.Label>
              <Form.Control
                type="email"
                placeholder="أدخل بريدك الإلكتروني"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <Form.Group as={Col} controlId="phoneNumber">
              <Form.Label>رقم الهاتف</Form.Label>
              <Form.Control
                type="tel"
                placeholder="أدخل رقم هاتفك"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <Form.Group as={Col} controlId="city">
              <Form.Label>اختر المدينة</Form.Label>
              <div style={{ position: "relative" }}>
                <Form.Control
                  as="select"
                  value={city}
                  onChange={(e) => handleCitySelect(e.target.value)}
                  required
                >
                  <option>المدية المنورة</option>
                  <option>مكة</option>
                  <option>الرياض</option>
                  <option>القصيم</option>
                </Form.Control>
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={6} md={6} xs={12}>
            <Form.Group as={Col} controlId="date">
              <Form.Label>تاريخ الطلب</Form.Label>
              <Form.Control
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Form.Group as={Col} controlId="time">
              <Form.Label>وقت الطلب</Form.Label>
              <Form.Control
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="subService">
            <Form.Label>اختر الخدمة الفرعية</Form.Label>
            <Form.Control
              as="select"
              value={subService}
              onChange={(e) => setSubService(e.target.value)}
              required
            >
              {serviceList.ServiceList &&
                serviceList.ServiceList.map((service, i) => (
                  <option key={i} value={service.id}>
                    {service.SubService}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Col lg={6} md={6} xs={12}>
            <Form.Group as={Col} controlId="paymentMethod">
              <Form.Label>طريقة الدفع</Form.Label>
              <Form.Control
                as="select"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                required
              >
                <option>كاش</option>
                <option>تحويل بنكي</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Form.Group as={Col} controlId="serviceProvider">
              <Form.Label>مزود الخدمة</Form.Label>
              {error && (
                <div className="alert alert-danger">
                  <span>{error}</span>
                </div>
              ) }
              
              <FormControl
                type="text"
                placeholder="ابحث عن مزود الخدمة..."
                onChange={handleSearch}
                value={searchTerm}
              />
              <div
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  marginTop: "5px",
                  border: "1px solid #ced4da",
                  borderRadius: "4px",
                  padding: "5px",
                }}
              >
                {serviceProvider.length === 0 ? (
                  <p>لا يوجد مزود لهذه الخدمة</p>
                ) : (
                  (searchTerm ? filteredProviders : serviceProvider).map(
                    (provider, index) => (
                      <Form.Check
                        key={provider._id}
                        type="checkbox"
                        label={provider.Name}
                        id={`provider-${index}`}
                        checked={provider.checked}
                        onChange={() => handleCheckboxChange(provider._id)}
                      />
                    )
                  )
                )}
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              as={Col}
              controlId="confirmationCheckbox"
              style={{ textAlign: "right" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Form.Check.Input
                  required
                  type="checkbox"
                  style={{ marginLeft: "10px", float: "right" }}
                />
                <Form.Check.Label style={{ marginBottom: "0" }}>
                  أنا متأكد أن هذه البيانات صحيحة وقد راجعت الطلب
                </Form.Check.Label>
              </div>
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100 mt-4">
              إرسال الطلب
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default OrderPage;

/*
 <Col md={12} lg={6}>
            <div className="mt-4">
              <h6>حدد موقعك على الخريطة</h6>
              {mapSrc && (
                <iframe
                  src={mapSrc}
                  width="100%"
                  height="400"
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              )}
            </div>
          </Col>
          */
