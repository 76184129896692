import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FormControl,
  Modal,
  Spinner
} from "react-bootstrap";
import { useParams, useNavigate , useLocation} from "react-router-dom";
import useUser from "../../hooks/useUser";
import { MDBInputGroup, MDBBtn } from "mdb-react-ui-kit";

const CompleteTheOrder = () => {
  const [customerName, setcustomerName] = useState();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [mapSrc, setMapSrc] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [serviceList, setServiceList] = useState("");
  const [subService, setSubService] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [serviceProvider, setServiceProvider] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProviders, setFilteredProviders] = useState([]);
  const { user, isLoading } = useUser();
  const [isSended, setIsSended] = useState(false);
  const { serviceId } = useParams();
  const [serviceProviderChecked, setServiceProviderChecked] = useState([]);
  const [error, setError] = useState("");
  const [select, setSelect] = useState(true);
  var   serviceProviderCheckedArray = [];
  const navigate = useNavigate();
  const location = useLocation();
  const [customerPrice,setCustomerPrice] = useState('');
  const [CompanyPrice,setCompanyPrice] = useState('');
  const [selectPriceModal, setSelectPriceModal] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const subServicName = queryParams.get("sub");

  const getServiceList = async () => {
    // Fetch service list from API based on service_id
    const token = await user.getIdToken();
    const headers = token ? { authtoken: token } : {};
    const resp = await axios.get(`/api/order/${serviceId}`, { headers });
    setServiceList(resp.data.service);
    const subServServiceProvider = resp.data.service.ServiceList.find((service) => service.SubService === subServicName)
    setServiceProvider(subServServiceProvider.subListServiceProvider);
    setCompanyPrice(subServServiceProvider.price)
    setSubService(resp.data.service.ServiceList[0].SubService);
    const userData = await axios.get(`/api/users`, { headers });
    console.log(userData.data)
    setcustomerName(userData.data.name);
    setCity(userData.data.city);
    setEmail(userData.data.email)
    setPhoneNumber(userData.data.phoneNumber);
    setPaymentMethod("كاش");
    setCity("المدينة المنورة");
  };

  useEffect(() => {
    if (!isLoading) {
      getServiceList();
      setEmail(user.email);
      setPhoneNumber(user.phoneNumber);

      const currentDate = new Date().toISOString().slice(0, 10);
      const currentTime = new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
  
      setDate(currentDate);
      setTime(currentTime);
    }
  }, [serviceId, isLoading]);

  const addOrderToDatabase = async (orderData) => {
    try {
      const token = await user.getIdToken();
      const response = await axios.post("/api/order/addorder", orderData, {
        headers: {
          "Content-Type": "application/json", // Example custom header
          authtoken: token, // Example authorization header
        },
      });
      setIsSended(true);
      if (response) getServiceList();
      setTimeout(navigate("/servicelist"), 2000);
    } catch (error) {
      console.error("Error adding order to database:", error);
      throw error;
    }
  };

  const handleCitySelect = (selectedCity) => {
    setCity(selectedCity);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filtered = serviceProvider.filter((provider) =>
      provider.Name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredProviders(filtered);
  };
  const handleClose = () => {setSelectPriceModal(false); setCustomerPrice('')}
  const handleShow = () => setSelectPriceModal(true); 

  const handleCheckboxChange = (providerId) => {
    // Filter checked providers immediately after checkbox change
    const checkedProviders = serviceProvider.filter(
      (provider) => provider.checked
    );
    setServiceProviderChecked(checkedProviders);

    setServiceProvider(
      serviceProvider.map((provider) =>
        provider._id === providerId
          ? { ...provider, checked: !provider.checked }
          : provider
      )
    );
  };

  useEffect(() => {
    serviceProviderCheckedArray.push(
      serviceProvider.filter((provider) => provider.checked)
    );
    console.log(serviceProviderCheckedArray);
  }, [serviceProviderChecked]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const orderData = {
      customerName,
      email,
      phoneNumber,
      city,
      date,
      time,
      subService:subServicName,
      customerPrice,
      CompanyPrice,
      paymentMethod,
      serviceProvider: serviceProviderCheckedArray,
      isReaddd: false,
      isConfirm: false,
      status: "جديدة",
      progress: 0,
      isReaded: false,
      messageStatus: false,
      cusConfirmation: false,
      isUserReadConfirm: false,
      confirmedBy: "",
      messages: [],
      companyAccept: [],
      companyImplemented: {},
      orderDate: new Date().toISOString().slice(0, 10)
    };

    try {
      if (serviceProviderCheckedArray.length === 0) {
        setError("الرجاء اختيار احد مزودي الخدمة اولا");
      } else {
        addOrderToDatabase(orderData);
     // console.log(orderData)
      }
    } catch (error) {
      console.error("Error submitting order:", error);
    
    }
  };

  const handleSubmitWithprice = async (event) => {
    const arr = []
    arr.push(serviceProvider.map(serviceProvider => serviceProvider))

    event.preventDefault();
    const orderData = {
      customerName,
      email,
      phoneNumber,
      city,
      date,
      time,
      subService:subServicName,
      customerPrice,
      CompanyPrice,
      paymentMethod,
      serviceProvider: arr,
      isReaddd: false,
      isConfirm: false,
      status: "جديدة",
      progress: 0,
      isReaded: false,
      messageStatus: false,
      cusConfirmation: false,
      isUserReadConfirm: false,
      confirmedBy: "",
      messages: [],
      companyAccept: [],
      companyImplemented: {},
      orderDate: new Date().toISOString().slice(0, 10)
    };
   
    try {
        //console.log(orderData);
        //console.log(arr)
        addOrderToDatabase(orderData);
      
      
    } catch (error) {
      console.error("Error submitting order:", error);
    
    }
  };

if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <Spinner animation="border" role="status">
            <span className="sr-only ">Loading...</span>
          </Spinner>
          </div>
        )}


  return (
    <Container>
      {isSended ? (
        <div
          className="alert alert-success d-flex align-items-center"
          role="alert"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-check-circle-fill me-2"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0ZM6.5 11a.5.5 0 0 0 .354.146l.646-.647a.5.5 0 1 1 .708.708l-1 1a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708l1.646 1.647Zm5.792-7.5a.5.5 0 0 0-.708-.708l-5 5a.5.5 0 0 0 .708.708l5-5a.5.5 0 0 0 0-.708Z"
            />
          </svg>
          تم إرسال طلب الخدمة بنجاح سيتم التواصل معك بواسطة مزود الخدمة الذي
          اخترته
        </div>
      ) : (
        <div></div>
      )}

      <Form onSubmit={select ? handleSubmit : handleSubmitWithprice}>
        <Row className="mb-3">
          <Col lg={6} md={6} xs={12}>
            <Form.Group as={Col} controlId="phoneNumber">
              <Form.Label>رقم الهاتف</Form.Label>
              <Form.Control
                type="tel"
                placeholder="أدخل رقم هاتفك"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Form.Group as={Col} controlId="city">
              <Form.Label>اختر المدينة</Form.Label>
              <div style={{ position: "relative" }}>
                <Form.Control
                  as="select"
                  value={city}
                  onChange={(e) => handleCitySelect(e.target.value)}
                  required
                >
                  <option>المدية المنورة</option>
                  <option>مكة</option>
                  <option>الرياض</option>
                  <option>القصيم</option>
                </Form.Control>
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={6} md={6} xs={12}>
            <Form.Group as={Col} controlId="date">
              <Form.Label>تاريخ الطلب</Form.Label>
              <Form.Control
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Form.Group as={Col} controlId="time">
              <Form.Label>وقت الطلب</Form.Label>
              <Form.Control
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3"></Row>

        <Row className="mb-3">
          <Col lg={6} md={12} xs={12}>
            <Form.Group as={Col} controlId="paymentMethod">
              <Form.Label>طريقة الدفع</Form.Label>
              <Form.Control
                as="select"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                required
              >
                <option>كاش</option>
                <option>تحويل بنكي</option>
              </Form.Control>
            </Form.Group>
          </Col>
         
            <Col lg={6} md={6} sm={12} style={{transition:".7s"}}>
            {select ? (
            <Form.Group as={Col} controlId="serviceProvider">
              <Form.Label>مزود الخدمة</Form.Label>
              {error && (
                <div className="alert alert-danger">
                  <span>{error}</span>
                </div>
              )}

              <FormControl
                type="text"
                placeholder="ابحث عن مزود الخدمة..."
                onChange={handleSearch}
                value={searchTerm}
               
              />
              <div
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  marginTop: "5px",
                  border: "1px solid #ced4da",
                  borderRadius: "4px",
                  padding: "5px",
                }}
              >
                {serviceProvider.length === 0 ? (
                  <p>لا يوجد مزود لهذه الخدمة</p>
                ) : (
                  (searchTerm ? filteredProviders : serviceProvider).map(
                    (provider, index) => (
                      <Form.Check
                        key={provider._id}
                        type="checkbox"
                        label={`${provider.Name} : ${provider.price}` }
                        id={`provider-${index}`}
                        checked={provider.checked}
                        onChange={() => handleCheckboxChange(provider._id)}
                      />
                    )
                  )
                )}
              </div>
            </Form.Group>
        ): (
            <Form.Group as={Col} controlId="customerPrice">
            <Form.Label>الميزانية</Form.Label>
            <MDBInputGroup className="mb-3">
            <input
              className="form-control"
              placeholder="اكتب السعر الذي تريده للخدمة "
              required = {select ? false : true}
              type="text"
              value={customerPrice}
              onChange={(e) => setCustomerPrice(e.target.value)}
            />
            <span className="mx-1">
             ريال سعودي
            </span>
          </MDBInputGroup>
          <div className="alert alert-warning"> ملحوظة :سيتم ارسال الطلب الى جميع مزود الخدمة و ستنتظر تلقي العروض </div>
          </Form.Group>
          )}
          
          </Col>

          
        </Row>

        <Row>
          <Col>
            <Form.Group
              as={Col}
              controlId="confirmationCheckbox"
              style={{ textAlign: "right" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Form.Check.Input
                  required
                  type="checkbox"
                  style={{ marginLeft: "10px", float: "right" }}
                />
                <Form.Check.Label style={{ marginBottom: "0" }}>
                  أنا متأكد أن هذه البيانات صحيحة وقد راجعت الطلب
                </Form.Check.Label>
              </div>
            </Form.Group>
            <Row>
              <Col md={6}>
                <Button variant="success" type="submit" className="w-100 mt-4">
                  إرسال الطلب 
                </Button>
             
              </Col>
              <Col md={6}>
                <Button variant="primary" onClick={()=> setSelect(!select)} className="w-100 mt-4">
                  {select ? "اضغط لكتابة سعر مخصص" : "تحديد مزود خدمة"}
                </Button>
              </Col>
              
            </Row>
          </Col>
        </Row>
      </Form>

        {/* select price modal */}
        <div>
        <Modal show={selectPriceModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>الميزانية</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>اكتب الميزانية (بالريال السعودي)</Form.Label>
                <Form.Control
                required
                  type="text"
                  value={customerPrice}
                  onChange={(e) => setCustomerPrice(e.target.value)}
                />
              </Form.Group>
              
            </Form>
          </Modal.Body>
          <Modal.Footer className="justify-content-start">
            <Button
              className="px-4 w-50"
              variant="primary"
              onClick={handleSubmitWithprice}
            >
              ارسال الطلب
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              الغاء
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
{/*End Add new service modal */}

    </Container>
  );
};

export default CompleteTheOrder;

/*
 <Col md={12} lg={6}>
            <div className="mt-4">
              <h6>حدد موقعك على الخريطة</h6>
              {mapSrc && (
                <iframe
                  src={mapSrc}
                  width="100%"
                  height="400"
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              )}
            </div>
          </Col>
          */
