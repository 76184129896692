import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  FormControl,
  Form,
  CardText,
  CardTitle,
  CardBody,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useParams } from "react-router-dom";
import Styles from "../../css/AdminDashBoard.module.css";
import {
  faBox,
  faCalendar,
  faCalendarDays,
  faCalendarWeek,
  faClipboardCheck,
  faClock,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import useUser from "../../hooks/useUser";
import {
  MDBSpinner,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { jwtDecode } from "jwt-decode";
import NotFoundPage from "../NotFoundPage";

const UserReport = () => {
  const getCookie = (name) => {
    const cookieValue = document.cookie.match(
      `(^|;)\\s*${name}\\s*=\\s*([^;]+)`
    );
    return cookieValue ? cookieValue.pop() : "";
  };

  const { type } = useParams();
  const { userId } = useParams();
  const { user, isLoading } = useUser();
  const [filteredUser, setFilteredUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [ordersInWeek, setOrdersInWeek] = useState();
  const [ordersInMonth, setOrdersInMonth] = useState();
  const [ordersInYear, setOrdersInYear] = useState();
  const [lastOrders, setLastOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const location = useLocation();
  const decodedUserId = JSON.parse(atob(userId));

  const [dateFrom, setDateFrom] = useState(
    getCookie("dateFrom")
      ? getCookie("dateFrom")
      : new Date().toISOString().slice(0, 10)
  );
  const [dateTo, setDateTo] = useState(
    getCookie("dateTo")
      ? getCookie("dateTo")
      : new Date().toISOString().slice(0, 10)
  );
  const [loading, setLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [checkIsAdmin, setCheckIsAdmin] = useState(false);

  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };

  const setCookies = (id) => {
    setCookie("userId", id, 1);
    //setCookie("dateTo", dateTo, 1);
  };
const isAdmin = async () => {
    try {
      const idTokenResult = await user.getIdTokenResult();
      // Check if the user has admin privileges
      return !!idTokenResult.claims.admin;
    } catch (error) {
      console.error("Error getting ID token:", error);
      return false;
    }
  };

  const getOrders = async () => {
    let resp;
    try {
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

      if (type === "customers") {
        resp = await axios.post(
          `/api/dashboard/users/customer/report`,
          { userId: decodedUserId },
          {
            headers,
          }
        );
      } else if (type === "companies") {
        resp = await axios.post(
          `/api/dashboard/users/companies/report`,
          { userId: decodedUserId },
          {
            headers,
          }
        );
      }

      const decodedOrders = jwtDecode(resp.data.token);
      const decodedUsersInWeek = jwtDecode(resp.data.token1);
      const decodedUsersInMonth = jwtDecode(resp.data.token2);
      const decodedUsersInYear = jwtDecode(resp.data.token3);

      setOrders(decodedOrders.data);
      setOrdersInWeek(decodedUsersInWeek.data.length);
      setOrdersInMonth(decodedUsersInMonth.data.length);
      setOrdersInYear(decodedUsersInYear.data.length);
      setLastOrders(decodedUsersInWeek.data)
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const handleDone = async () => {
    let resp;
    try {
      setLoading(true); // Set loading to true when starting data fetching
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

      if (type === "customers") {
        resp = await axios.post(
          `/api/dashboard/users/customer/report/lastorders`,
          { userId: decodedUserId,
            dateFrom: dateFrom,
            dateTo: dateTo
           },
          {
            headers,
          }
        );
      } else if (type === "companies") {
        resp = await axios.post(
            `/api/dashboard/users/company/report/lastorders`,
            { userId: decodedUserId,
              dateFrom: dateFrom,
              dateTo: dateTo
             },
            {
              headers,
            }
          )
      }

      const decodedToken = jwtDecode(resp.data);
      setLastOrders(decodedToken.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false); // Set loading to false when done fetching data
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filtered = users.filter((user) =>
      user.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredUser(filtered);
  };

  useEffect(() => {
    if (!isLoading && user) {
     isAdmin().then((isadmin) => {
        if (isadmin) {
          setCheckIsAdmin(true);
          getOrders();
        } else {
          setCheckIsAdmin(false);
        }
      });
    }
  }, [isLoading]);

  if (!checkIsAdmin && !isLoading) {
    return <NotFoundPage />;
  }

  if (usersLoading) {
    return (
      <div className="text-center" style={{ marginTop: "300px" }}>
        {" "}
        <MDBSpinner
          style={{ width: "100px", height: "100px" }}
          grow
          role="status"
          className="me-2"
        />{" "}
      </div>
    );
  }
  return (
    <div>
      <div className="container">
        <Row> </Row>

        <Row className="mt-4">
          <div>
            <h5> المستخدم : {}</h5>
            <h4 style={{fontSize:"24px"}}> عدد الطلبات في : {}</h4>
          </div>
          <Col md={4} className="text-center mt-3">
            <Card style={{ boxShadow: "none" }}>
              <Card.Header>
                <FontAwesomeIcon icon={faCalendarWeek} /> الاسبوع
              </Card.Header>
              <Card.Body>
                <h1>{ordersInWeek}</h1>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="text-center mt-3">
            <Card style={{ boxShadow: "none" }}>
              <Card.Header>
                <FontAwesomeIcon icon={faCalendar} /> الشهر
              </Card.Header>
              <Card.Body>
                <h1>{ordersInMonth}</h1>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="text-center mt-3">
            <Card style={{ boxShadow: "none" }}>
              <Card.Header>
                <FontAwesomeIcon icon={faCalendarDays} /> السنة
              </Card.Header>
              <Card.Body>
                <h1>{ordersInYear}</h1>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <div>
            <h4 style={{fontSize:"24px"}}> عدد الطلبات :</h4>
          </div>
          <Col md={3} className="text-center mt-3">
            <Link
              disabled={loading}
              onClick={() => setCookies()}
              to={`/dashboard/userslist/${type}/${userId}/userorders/allorders`}
            >
              <Card style={{ boxShadow: "none" }}>
                <Card.Header>
                  <FontAwesomeIcon icon={faBox} /> الكلي
                </Card.Header>
                <Card.Body>
                  <h1>{orders.length ? orders.length : 0}</h1>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={3} className="text-center mt-3">
            <Link
              onClick={() => setCookies()}
              to={`/dashboard/userslist/${type}/${userId}/userorders/completedorders`}
            >
              <Card style={{ boxShadow: "none" }}>
                <Card.Header>
                  <FontAwesomeIcon icon={faClipboardCheck} /> المكتملة
                </Card.Header>
                <Card.Body>
                  <h1>
                    {orders.length
                      ? orders.filter((order) => order.status === "مكتملة")
                          .length
                      : 0}
                  </h1>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={3} className="text-center mt-3">
            <Link
              onClick={() => setCookies()}
              to={`/dashboard/userslist/${type}/${userId}/userorders/inprogressorders`}
            >
              <Card style={{ boxShadow: "none" }}>
                <Card.Header>
                  <FontAwesomeIcon icon={faClock} /> قيد التنفيذ
                </Card.Header>
                <Card.Body>
                  <h1>
                    {orders.length
                      ? orders.filter((order) => order.status === "قيد التنفيذ")
                          .length
                      : 0}
                  </h1>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={3} className="text-center mt-3">
            <Link
              onClick={() => setCookies()}
              to={`/dashboard/userslist/${type}/${userId}/userorders/infoorders`}
            >
              <Card style={{ boxShadow: "none" }}>
                <Card.Header>
                  <FontAwesomeIcon icon={faInfo} /> قيد الاستفسار
                </Card.Header>
                <Card.Body>
                  <h1>
                    {orders.length
                      ? orders.filter((order) => order.status === "استفسار")
                          .length
                      : 0}
                  </h1>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg={6} md={6} sm={12}>
            <Form.Group as={Col} controlId="date">
              <Form.Label>من</Form.Label>
              <Form.Control
                type="date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Form.Group as={Col} controlId="date">
              <Form.Label>إلى</Form.Label>
              <Form.Control
                type="date"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col lg={4}></Col>
          <Col lg={4}>
            <MDBBtn
              className="mt-4 w-100"
              onClick={handleDone}
              disabled={loading}
            >
              {loading ? (
                <MDBSpinner grow role="status" className="me-2" />
              ) : (
                "تجهيز"
              )}
            </MDBBtn>
          </Col>
          <Col lg={4}></Col>
        </Row>

        <Row>
          {lastOrders &&
            lastOrders.map((order, index) => (
              <Link
                key={index}
                to={{
                  pathname: `/dashboard/order/${btoa(
                    JSON.stringify(order._id)
                  )}`,
                }}
              >
                <Card className="my-3">
                  <CardBody>
                    <CardTitle>{order.customerName}</CardTitle>
                    <Row>
                      <Col sm={4} md={6} className="mt-2">
                        <CardText>
                          <strong>الايميل : </strong> {order.email}
                        </CardText>
                      </Col>
                      <Col md={6} className="mt-2">
                        <CardText>
                          <strong>الطلب :</strong> {order.subService}
                        </CardText>
                      </Col>

                      <Col md={6} className="mt-3">
                        <CardText>
                          <strong>حالة الطلب :</strong> {order.status}
                        </CardText>
                      </Col>

                      <Col md={6} className="mt-3">
                        <CardText>
                          <strong>تاريخ نشر الطلب :</strong> {order.date}
                        </CardText>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Link>
            ))}
        </Row>
      </div>
    </div>
  );
};

export default UserReport;
