import React, { useState, useEffect } from "react";
import { Card, CardTitle, Row, Col, CardBody, Container ,CardText} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useParams } from "react-router-dom";
import Styles from "../../css/AdminDashBoard.module.css";
import {
  faBox,
  faCalendar,
  faCalendarDays,
  faCalendarWeek,
  faClipboardCheck,
  faClock,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import useUser from "../../hooks/useUser";
import {
  MDBSpinner,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { jwtDecode } from "jwt-decode";
import NotFoundPage from "../NotFoundPage";



const UserOrders = () => {
    const { type } = useParams();
    const { userId } = useParams();
    const { orderstype } = useParams();
    const [orders, setOrders] = useState([]);
    const { user, isLoading } = useUser();
    const decodedUserId = JSON.parse(atob(userId));
    const [loading, setLoading] = useState(false);
    const [checkIsAdmin, setCheckIsAdmin] = useState(false);

    const isAdmin = async () => {
        try {
          const idTokenResult = await user.getIdTokenResult();
          // Check if the user has admin privileges
          return !!idTokenResult.claims.admin;
        } catch (error) {
          console.error("Error getting ID token:", error);
          return false;
        }
      };

    const getOrders = async () => {
        let resp;
        try {
            setLoading(true);
          const token = await user.getIdToken();
          const headers = token ? {authtoken:token} : {}
         
          if (type === "customers") {
            resp = await axios.post(`/api/dashboard/users/customer/report`, {userId:decodedUserId}, {
              headers,
            });
          } else if (type === "companies") {
            resp = await axios.post(`/api/dashboard/users/companies/report`, {userId:decodedUserId}, {
              headers,
            });
          }
          
          const decodedOrders = jwtDecode(resp.data.token);
          if(orderstype === 'allorders'){
            setOrders(decodedOrders.data);
          } else if (orderstype === 'completedorders'){
            setOrders(decodedOrders.data.filter(order => order.status === 'مكتملة'))
          } else if (orderstype === 'inprogressorders'){
            setOrders(decodedOrders.data.filter(order => order.status === 'قيد التنفيذ'))
          } else if (orderstype === 'infoorders'){
            setOrders(decodedOrders.data.filter(order => order.status === 'استفسارات'))
          }
          
          
    
         
        } catch (error) {
          console.error("Error fetching orders:", error);
        } finally {
            setLoading(false);
        }
      };

      useEffect(() => {
        if (!isLoading && user) {
     isAdmin().then((isadmin) => {
        if (isadmin) {
          setCheckIsAdmin(true);
          getOrders();
        } else {
          setCheckIsAdmin(false);
        }
      });
    }
      }, [isLoading]);
      
      if (!checkIsAdmin && !isLoading) {
        return <NotFoundPage />;
      }
    

      if (loading) {
        return (
          <div className="text-center" style={{ marginTop: "300px" }}>
            {" "}
            <MDBSpinner
              style={{ width: "100px", height: "100px" }}
              grow
              role="status"
              className="me-2"
            />{" "}
          </div>
        );
      }

    return ( 
        <Container>
        {orders &&
          orders.map((order, index) => (
            <Link
              key={index}
              to={{
                pathname: `/dashboard/order/${btoa(JSON.stringify(order._id))}`,
              }}
            >
              <Card className="my-3">
                <CardBody>
                  <CardTitle>{order.customerName}</CardTitle>
                  <Row>
                    <Col sm={4} md={6} className="mt-2">
                      <CardText>
                        <strong>الايميل : </strong> {order.email}
                      </CardText>
                    </Col>
                    <Col md={6} className="mt-2">
                      <CardText>
                        <strong>الطلب :</strong> {order.subService}
                      </CardText>
                    </Col>
  
                    <Col md={6} className="mt-3">
                      <CardText>
                        <strong>حالة الطلب :</strong> {order.status}
                      </CardText>
                    </Col>
  
                    <Col md={6} className="mt-3">
                      <CardText>
                        <strong>تاريخ نشر الطلب :</strong> {order.date}
                      </CardText>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Link>
          ))}
      </Container>

     );
}
 
export default UserOrders;