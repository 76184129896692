import axios from "axios";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
  ProgressBar,
} from "react-bootstrap";
import { Link, useParams ,useNavigate} from "react-router-dom";
import useUser from '../../hooks/useUser';
import Login from "../Login";

const CompanyOrderList = () => {
  const [orders, setOrders] = useState([]);
  const { user ,isLoading } = useUser();
  const navigate =  useNavigate()

  const getOrders = async () => {
    try {
      const token = await user.getIdToken();
      const headers = token ? {authtoken:token} : {}

      const resp = await axios.get(`/api/companyuser/orderlist`,{headers});
      const newOrders = resp.data;
      setOrders(newOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  
  useEffect(() => {
    
    if (!isLoading && user) 
     getOrders();
  }, [isLoading]);


  if(!isLoading){
    if(!user)
    return(
      <Login />
    )
  }

 

  return (
    <Container>
      {orders.map((order, index) => (
        <Link key={index} to={{
          pathname: `/companydashboard/orderlist/orderdetails/${btoa(
            JSON.stringify(order._id)
          )}`
        }}>
          <Card className="my-3">
            <CardBody>
              <CardTitle>{order.customerName}</CardTitle>
              <Row>
                <Col sm={3}>
                  <CardText>
                    <strong>الايميل : </strong> {order.email}
                  </CardText>
                </Col>
                <Col sm={3}>
                  <CardText>
                    <strong>الطلب :</strong> {order.subService}
                  </CardText>
                </Col>
                <Col sm={3}>
                  <CardText>
                    <strong>حالة الطلب :</strong> {order.status}
                  </CardText>
                </Col>
                <Col sm={3}>
                  <CardText>
                    <strong>تاريخ نشر الطلب :</strong> {order.orderDate}
                  </CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Link>
      ))}
    </Container>
  );
};

export default CompanyOrderList;
