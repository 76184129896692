import { useState } from "react"
import { Link , useNavigate } from "react-router-dom"
import { getAuth,createUserWithEmailAndPassword } from "firebase/auth"
const CreateAccountPage = () =>{
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [confirmPassword,setConfirmPassword] = useState('')
    const [error ,setError] = useState('')

    const navigate = useNavigate()

    const CreateAccount = async ()=>{
        try{
            if(password !== confirmPassword){
                setError("Pasword and confirm password don not match ")
                return
            } 

            await createUserWithEmailAndPassword(getAuth(), email , password)
            navigate('/articles')
          

            
        } catch (e){
         setError(e.message)
        }
   
    }

    return (
        <div>
        <h1>Create Account</h1>
        {error && <p className="error">{error}</p>}
        <input 
        placeholder="Your Email Address"
        value={email}
        onChange={e => setEmail(e.target.value)} />
        <input 
        type="password"
        placeholder="Your Password"
        value={password}
        onChange={e => setPassword(e.target.value)}
        />
        <input 
        type="password"
        placeholder="Coffirm Password"
        value={confirmPassword}
        onChange={e => setConfirmPassword(e.target.value)}
        />
        <button onClick={CreateAccount}>Create Account</button>
        <Link to="/login">Alredy have an account ? Log in here</Link>
        </div>
    )
}

export default CreateAccountPage