import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import useUser from "../hooks/useUser";
const LoadingSpinner = () => (
  <Spinner animation="border" role="status">
    <span className="sr-only">Loading...</span>
  </Spinner>
);

const ServiceListPage = () => {
  const [services, setServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true); // State to manage loading status
const {user , isLoading } = useUser()
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = await user.getIdToken();
        const headers = token ? {authtoken:token} : {}
    
        const response = await axios.get("/api/servicelist",{headers});
        setServices(response.data);
        console.log(response.data)
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };
if(!isLoading){
    fetchServices();
}
  }, [isLoading]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredServices = services.filter((service) =>
    service.ServiceName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container>
      <Row className="justify-content-md-center my-4">
        <Col md={8}>
          <Form className="search-form">
            <FormControl
              type="text"
              placeholder="ابحث عن خدمة..."
              className="search-input"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Form>
        </Col>
      </Row>
      <Row>
        {loading || isLoading ? (
          <Col md={12} className="text-center">
            <LoadingSpinner />
          </Col>
        ) : filteredServices.length === 0 ? (
          <Col md={12} className="text-center">
            <h4>لا توجد خدمات</h4>
          </Col>
        ) : (
          <Suspense fallback={<LoadingSpinner />}>
            {filteredServices.map((service, index) => (
              <Col key={index} md={4} className="mb-4">
                <Link to={`/services/subservices/${service._id}`}>
                  {" "}
                  <Card className="service-card">
                    <Card.Body className="text-center">
                      <FontAwesomeIcon
                        icon={faServer}
                        size="4x"
                        className="service-icon Primary"
                      />
                      <Card.Title className="service-title">
                        {service.ServiceName}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
          </Suspense>
        )}
      </Row>
    </Container>
  );
};

export default ServiceListPage;
