import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, FormControl, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import Styles from "../../css/AdminDashBoard.module.css";
import {
  faBox,
  faCalendar,
  faCalendarDays,
  faCalendarWeek,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import useUser from "../../hooks/useUser";
import {
  MDBSpinner,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { jwtDecode } from "jwt-decode";
import NotFoundPage from "../NotFoundPage";

const Users = () => {
  const getCookie = (name) => {
    const cookieValue = document.cookie.match(
      `(^|;)\\s*${name}\\s*=\\s*([^;]+)`
    );
    return cookieValue ? cookieValue.pop() : "";
  };
  const { type } = useParams();
  const [orders, setOrders] = useState([]);
  const { user, isLoading } = useUser();
  const [filteredUser, setFilteredUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [usersInWeek, setUsersInWeek] = useState();
  const [usersInMonth, setUsersInMonth] = useState();
  const [usersInYear, setUsersInYear] = useState();
  const [checkIsAdmin, setCheckIsAdmin] = useState(false);

  const [dateFrom, setDateFrom] = useState(
    getCookie("dateFrom")
      ? getCookie("dateFrom")
      : new Date().toISOString().slice(0, 10)
  );
  const [dateTo, setDateTo] = useState(
    getCookie("dateTo")
      ? getCookie("dateTo")
      : new Date().toISOString().slice(0, 10)
  );
  const [loading, setLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);

  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };

  const setCookies = (id) => {
    setCookie("userId", id, 1);
    //setCookie("dateTo", dateTo, 1);
  };
 
  const isAdmin = async () => {
    try {
      const idTokenResult = await user.getIdTokenResult();
      // Check if the user has admin privileges
      return !!idTokenResult.claims.admin;
    } catch (error) {
      console.error("Error getting ID token:", error);
      return false;
    }
  };

  const getUsers = async () => {
    
    let resp;
    try {
      // Set loading to true when starting data fetching
      setUsersLoading(true);
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};
      if (type === "customers") {
        resp = await axios.post(`/api/dashboard/users/customer`, null, {
          headers,
        });
      } else if (type === "companies") {
        resp = await axios.post(`/api/dashboard/users/companies`, null, {
          headers,
        });
      }  else if (type === "admins") {
        resp = await axios.post(`/api/dashboard/users/admins`, null, {
          headers,
        });
      }

      if(type === "admins") {
        const decodedUsers = jwtDecode(resp.data.token);
        setUsers(decodedUsers.data);
      } else {
        const decodedUsers = jwtDecode(resp.data.token);
        const decodedUsersInWeek = jwtDecode(resp.data.token1);
  
        const decodedUsersInMonth = jwtDecode(resp.data.token2);
        const decodedUsersInYear = jwtDecode(resp.data.token3);
  
        setUsers(decodedUsers.data);
        setUsersInWeek(decodedUsersInWeek.data.length);
        setUsersInMonth(decodedUsersInMonth.data.length);
        setUsersInYear(decodedUsersInYear.data.length);
      }
      
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setUsersLoading(false); // Set loading to false when done fetching data
    }
  };

  const handleDone = async () => {
    let resp;
    console.log(dateFrom)
    try {
      setLoading(true); // Set loading to true when starting data fetching
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

      if (type === "customers") {
        resp = await axios.post(
          `/api/dashboard/users/customerwithdate`,
          { dateFrom, dateTo },
          { headers }
        );
      } else {
        resp = await axios.post(
          `/api/dashboard/users/companieswithdate`,
          { dateFrom, dateTo },
          { headers }
        );
      }
      
      const decodedToken = jwtDecode(resp.data);
      console.log(resp);
      setUsers(decodedToken.data);
    } catch (error) {
      console.error("Error fetching Users:", error);
    } finally {
      setLoading(false); // Set loading to false when done fetching data
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filtered = users.filter((user) =>
      user.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredUser(filtered);
  };

  useEffect(() => {
    if (!isLoading && user) {
     isAdmin().then((isadmin) => {
        if (isadmin) {
          setCheckIsAdmin(true);
          getUsers();
        } else {
          setCheckIsAdmin(false);
        }
      });
    }
  }, [isLoading]);

  if (!checkIsAdmin && !isLoading) {
    return <NotFoundPage />;
  }


  if (usersLoading) {
    return (
      <div className="text-center" style={{ marginTop: "300px" }}>
        {" "}
        <MDBSpinner
          style={{ width: "100px", height: "100px" }}
          grow
          role="status"
          className="me-2"
        />{" "}
      </div>
    );
  }

  if(type === "admins"){
    return(
      <div className="container">
        <Row>
          {" "}
          <h2>فريق المنصة </h2>{" "}
        </Row>
        <Row className="mt-5">

        <MDBTable striped>
          <MDBTableHead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">الاسم</th>
              <th scope="col">البريد الالكتروني</th>
              <th scope="col">تاريخ الانضمام</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {(searchTerm ? filteredUser : users).map((user, i) => (
              <tr key={user.Email}>
                <th scope="row">{i + 1}</th>
                <td>
                    {user.name}
                </td>
                <td>{user.email}</td>
                <td>{user.createdAt}</td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </Row>

        </div>
    )
  }


  return (
    <div>
      <div className="container">
        <Row>
          {" "}
          <h2>المستخدمين</h2>{" "}
        </Row>

        <Row className="mt-4">
          <div>
            <h4 style={{fontSize:"24px"}}> عدد المستخدمين في : </h4>
          </div>
          <Col md={4} className="text-center mt-3">
            <Card style={{ boxShadow: "none" }}>
              <Card.Header>
                <FontAwesomeIcon icon={faCalendarWeek} /> الاسبوع
              </Card.Header>
              <Card.Body>
                <h1>{usersInWeek}</h1>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="text-center mt-3">
            <Card style={{ boxShadow: "none" }}>
              <Card.Header>
                <FontAwesomeIcon icon={faCalendar} /> الشهر
              </Card.Header>
              <Card.Body>
                <h1>{usersInMonth}</h1>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="text-center mt-3">
            <Card style={{ boxShadow: "none" }}>
              <Card.Header>
                <FontAwesomeIcon icon={faCalendarDays} /> السنة
              </Card.Header>
              <Card.Body>
                <h1>{usersInYear}</h1>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg={6} md={6} sm={12}>
            <Form.Group as={Col} controlId="date">
              <Form.Label>من</Form.Label>
              <Form.Control
                type="date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Form.Group as={Col} controlId="date">
              <Form.Label>إلى</Form.Label>
              <Form.Control
                type="date"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col lg={4}></Col>
          <Col lg={4}>
            <MDBBtn
              className="mt-4 w-100"
              onClick={handleDone}
              disabled={loading}
            >
              {loading ? (
                <MDBSpinner grow role="status" className="me-2" />
              ) : (
                "تجهيز"
              )}
            </MDBBtn>
          </Col>
          <Col lg={4}></Col>
        </Row>
        <Row className="mt-4">
          <Col md={2}></Col>
          <Col md={8}>
            <FormControl
              type="text"
              placeholder="بحث   ..."
              onChange={handleSearch}
              value={searchTerm}
            />
          </Col>
          <Col md={2}></Col>
        </Row>

        <Row>
          <MDBTable striped>
            <MDBTableHead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">الاسم</th>
                <th scope="col">البريد الالكتروني</th>
                <th scope="col">تاريخ الانضمام</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {(searchTerm ? filteredUser : users).map((user, i) => (
                <tr key={user.Email}>
                  <th scope="row">{i + 1}</th>
                  <td>
                    <Link
                      onClick={setCookies(user.id)}
                      to={`/dashboard/userslist/${type}/${btoa(
                        JSON.stringify(user.id)
                      )}`}
                    >
                      {" "}
                      {user.name}{" "}
                    </Link>
                  </td>
                  <td>{user.email}</td>
                  <td>{user.createdAt}</td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        </Row>
      </div>
    </div>
  );
};

export default Users;
