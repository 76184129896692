import { Link, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import useUser from "../hooks/useUser";
import Container from "react-bootstrap/Container";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  Modal,
  Offcanvas,
  Button,
  Dropdown,
} from "react-bootstrap";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBCollapse,
} from "mdb-react-ui-kit";

import { useEffect, useState } from "react";
let type = localStorage.getItem("type");

const NavBar = () => {
  useEffect(() => {
   console.log(type)
  },[])
  const [openNavText, setOpenNavText] = useState(true);
  const { user, isLoading } = useUser();
  const navigate = useNavigate();
  return (
    <div>
      {["lg"].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
          <Container fluid>
            <Navbar.Brand href="/">منصة مجال البناء</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  منصة مجال البناء
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="my-2 m-auto">
                  <Nav.Link className="mx-2" href="/">
                    الرئيسية
                  </Nav.Link>
                  <Nav.Link className="mx-2" href="" aria-label="Close">
                    {user && (
                      <Link
                        to={`${
                          type === "company"
                            ? "/companydashboard"
                            : type === "customer"
                            ? "/customerdashboard"
                            : "/dashboard"
                        }`}
                      >
                        لوحة التحكم{" "}
                      </Link>
                    )}
                  </Nav.Link>
                  <Nav.Link className="mx-2" href="#features">
                    {!user && "مميزاتنا"}
                  </Nav.Link>
                  <Nav.Link className="mx-2" href="#about">
                    {!user && "عن المنصة"}
                  </Nav.Link>
                  <Nav.Link className="mx-2" href="#services">
                    {!user && "خدماتنا"}
                  </Nav.Link>

                  <Nav.Link className="mx-2" href="#contact">
                    {!user && "تواصل معنا"}
                  </Nav.Link>
                </Nav>

                {user ? (
                  <MDBBtn
                    id="navbarScrollingDropdown"
                    className="mx-5 p-2 d-block col-md-10 col-lg-2"
                    style={{ display: "block" }}
                    color="secondary"
                    onClick={() => {
                      signOut(getAuth());
                      navigate("/login");
                    }}
                  >
                    تسجيل خروج
                  </MDBBtn>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      تسجيل دخول
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="">
                      <Dropdown.Item href="#/action-1">
                        <Link to={"/login"}>تسجيل دخول </Link>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        <Link to={"/customersregister"}> انشاء حساب فرد </Link>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        <Link to={"/companyregister"}> انشاء حساب شركة </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </div>
  );
};

export default NavBar;

/*

<nav>
<ul>
  <li>
     <Link to='/'>Home</Link>
   </li>
  <li>
     <Link to='/about'>About</Link>
   </li>
  <li>
     <Link to='/articles'>Articles</Link>
   </li>
 
</ul>
<div className="nav-right">
 {user 
 ? <button
    onClick={() => {signOut(getAuth())}}
 >Log Out</button>
 :<button onClick={()=> {navigate('/login')}}>Log In</button>
}
</div>
</nav>



  <Navbar bg="light" data-bs-theme="light">
        <Container>
          <Navbar.Brand href="#home">Navbar</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="my-2 m-auto">
            <Nav.Link className="mx-2" href="#home">الرئيسية</Nav.Link>
            <Nav.Link className="mx-2" href="#features">عن المنصة</Nav.Link>
            <Nav.Link className="mx-2" href="#pricing">الخدمات</Nav.Link>
          </Nav>
          <nav className="ms-2">
          <Nav.Link className="mx-2" href="#pricing">
          <Link>
          <MDBBtn className='me-1' color='secondary'> تسجيل دخول </MDBBtn>
          </Link>
          <Link>
          <MDBBtn className='me-1' color='secondary'> انشاء حساب </MDBBtn>
          </Link>
          </Nav.Link>
          </nav>
         </Navbar.Collapse>
        </Container>
      </Navbar>
*/
