import React ,{useEffect} from "react";
import WOW from 'wowjs';
import { Container, Row, Col, Tabs, Tab, Button } from 'react-bootstrap';

export const Gallery = (props) => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const steps = [
    'التسجيل',
    'تأكيد الحساب',
    'لوحة التحكم',
    'انشاء طلب',
    'تأكيد الطلب'
  ];

  return (
    <Container id="manual" className="text-center">
    <Row>
      <Col>
        <div className="section-title">
          <h2>كيف تعمل المنصة</h2>
          <p>تعتمد منصتنا على نموذج يربط بين مزودي الخدمات في مجالات البناء والهندسة والفحص مع العملاء الذين يبحثون عن خدمات متنوعة وذات جودة عالية. يمكن للعملاء استعراض مجموعة واسعة من الخدمات المقدمة، واختيار الخدمات المناسبة لاحتياجاتهم، والتواصل مباشرة مع مزودي الخدمات بعد انشاء الطلب لتلبية متطلباتهم. بفضل هذا النموذج، يتم تسهيل عملية البحث عن الخدمات وتقديمها، وتحقيق رضا العملاء والمزودين على حد سواء.</p>
        </div>
      </Col>
    </Row>
    <Row>
    <div className="stepper-container">
    {steps.map((step, index) => (
      <div key={index} className={`step wow animate__fadeInRightBig `}  data-wow-offset="100" data-wow-duration={`${2 + index}s`}>
        <div className="step-number">{`${index + 1}`}</div>
        <div className="step-description">{step}</div>
      </div>
    ))}
  </div>
    </Row>
  </Container>
  );
};
