import axios from "axios";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
  ProgressBar,
} from "react-bootstrap";
import {Link} from "react-router-dom";
import useUser from '../../hooks/useUser';
import NotFoundPage from "../NotFoundPage";
import { MDBSpinner } from "mdb-react-ui-kit";

const AdminAllOrders = () => {
  const [orders, setOrders] = useState([]);
  const { user ,isLoading } = useUser();
  const [checkIsAdmin, setCheckIsAdmin] = useState(false);
  const [loading , setLoading] = useState(false);
  const isAdmin = async () => {
    try {
      const idTokenResult = await user.getIdTokenResult();
      // Check if the user has admin privileges
      return !!idTokenResult.claims.admin;
    } catch (error) {
      console.error("Error getting ID token:", error);
      return false;
    }
  };
  
  const getOrders = async () => {
    try {
      setLoading(true)
      const token = await user.getIdToken();
      const headers = token ? {authtoken:token} : {}
     
      const resp = await axios.get(`/api/admin/allorders`,{headers});
      setOrders(resp.data);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  
  useEffect(() => {
    
    if (!isLoading && user) {
     isAdmin().then((isadmin) => {
        if (isadmin) {
          setCheckIsAdmin(true);
          getOrders();
        } else {
          setCheckIsAdmin(false);
        }
      });
    }
  }, [isLoading]);

  if (!checkIsAdmin && !isLoading) {
    return <NotFoundPage />;
  }

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: "300px" }}>
        {" "}
        <MDBSpinner
          style={{ width: "100px", height: "100px" }}
          grow
          role="status"
          className="me-2"
        />{" "}
      </div>
    );
  }

  return (
    <Container>
      {orders.map((order, index) => (
        <Link key={index} to={{
          pathname: `/dashboard/order/${btoa(
            JSON.stringify(order._id)
          )}`
        }}>
          <Card className="my-3">
            <CardBody>
              <CardTitle>{order.customerName}</CardTitle>
              <Row>
                <Col sm={4} md = {6} className="mt-2">
                  <CardText>
                    <strong>الايميل : </strong> {order.email}
                  </CardText>
                </Col>
                <Col  md = {6} className="mt-2">
                  <CardText>
                    <strong>الطلب :</strong> {order.subService}
                  </CardText>
                </Col>
                    <Col  md = {6} lg={6} className="mt-3">
                      <CardText>
                        <strong>حالة الطلب :</strong> {order.status}
                      </CardText>
                    </Col>

                    <Col md={6} className="mt-3">
                    <CardText>
                      <strong>تاريخ نشر الطلب :</strong> {order.date}
                    </CardText>
                  </Col>

                  
              </Row>
            </CardBody>
          </Card>
        </Link>
      ))}
    </Container>
  );
};

export default AdminAllOrders;
