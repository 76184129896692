import React from 'react';
import { Container, Row, Col, Alert, Image } from 'react-bootstrap';
import { FaHourglassHalf } from 'react-icons/fa';
import waitingImage from '../../src/assets/images/wait.svg'; // تأكد من أن لديك صورة SVG مناسبة في هذا المسار

const WaitingApprove = () => {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
      <Row>
        <Col md={12} className="text-center">
          <div variant="info" className="p-4 alert alert-info">
            <FaHourglassHalf size={50} className="mb-3" />
            <Alert.Heading>حسابك قيد المراجعة</Alert.Heading>
            <p>
              ستتمكن من الدخول عند الموافقة على الانضمام. نرجو منك الانتظار بينما نقوم بمراجعة طلبك.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={12} className="text-center">
          <Image src={waitingImage} alt="Waiting for review" fluid style={{ }} />
        </Col>
      </Row>
    </Container>
  );
};

export default WaitingApprove;
