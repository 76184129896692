import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Spinner,
  Alert,
} from "react-bootstrap";
import useUser from "../hooks/useUser"; // Custom hook for user data from Firebase
import {
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
  EmailAuthProvider,
  updateProfile,
  updateEmail,
  updatePhoneNumber,
  RecaptchaVerifier,
  PhoneAuthProvider,
  signInWithPhoneNumber,
} from "firebase/auth";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Phone } from "react-bootstrap-icons";

function UserProfile() {
  const { user, isLoading } = useUser();
  const [name, setName] = useState("");
  const [address, setAdress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [profileImage, setProfileImage] = useState("https://as1.ftcdn.net/v2/jpg/03/46/83/96/1000_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg");
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordFieldsVisible, setPasswordFieldsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const navigate = useNavigate();


  const getData = async () => {
    const token = await user.getIdToken();
    const headers = token ? { authtoken: token } : {};
    try{
      const resp = await axios.get(`/api/users`, { headers });
      setName(resp.data.name );
      setAdress(resp.data.address);
      setEmail(resp.data.email); // User's profile image
      setPhone(resp.data.phoneNumber);
      if (resp.data.profileImage )
        setProfileImage(`https://magalaelbinaa.dafaalla.com/${resp.data.profileImage}`);
    } catch {
      console.log("error")
    }
    
  };

  useEffect(() => {

    if (!isLoading && user) {
      getData()
    }
  }, [isLoading]);

  const handleImageChange = (event) =>{
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        setProfileImageFile(file); // تعيين الملف المختار
      };
      reader.readAsDataURL(file);
    }
  };

  const changePassword = async (currentPassword, newPassword) => {
    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );

    try {
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      return true;
    } catch (error) {
      console.error("Error changing password:", error);
      throw error;
    }
  };
  const handlePasswordChange = async () => {
    if (newPassword !== confirmNewPassword) {
      setErrorMessage("كلمة المرور الجديدة غير متطابقة مع تأكيدها.");
      console.log(newPassword.length < 7);
      return;
    }
    if (newPassword.length < 7) {
      setErrorMessage("يجب الا تقل كبمة المرور عن 8 احرف او ارقام على الاقل.");
      return;
    }
    setLoading(true);
    try {
      await changePassword(currentPassword, newPassword);
      setLoading(false);
      setErrorMessage("");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
      setRefreshData(true);
    } catch (error) {
      setErrorMessage("كلمة السر الحالية خطأ");
      setLoading(false);
    }
  };

  const saveProfile = async () => {
    const auth = getAuth(); // Get the auth instance
    const user = auth.currentUser;
     
    try {
      await updateProfile(user, {
        displayName: name,
        phoneNumber: phone,
      });
      if (user.email !== email) {
        await updateEmail(user, email);
      }
      
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};
  
      // Create FormData instance and append fields
      const formData = new FormData();
      formData.append('name', name);
      formData.append('address', address);
      formData.append('email', email);
      formData.append('phoneNumber', phone);
      formData.append('file', profileImageFile); // profileImageFile is the file object
  
      const resp = await axios.put(`/api/users/update`,
        formData,
        { headers, headers: { ...headers, 'Content-Type': 'multipart/form-data' } }
      );
      if(resp.status === 200) {
        getData()
        setRefreshData(true);
      }
        
      setLoading(false);
      setErrorMessage("");
    } catch (error) {
      console.error("Error updating profile:", error);
      setErrorMessage(error.message);
      setLoading(false);
    }
  };
  

  if (!isLoading) {
    if (!user) return navigate("/customerlogin");
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Form>
          <div className="text-center mb-3">
            <Image
              src={profileImage} // Default profile image
              alt="Profile"
              roundedCircle
              style={{ cursor: "pointer", width: "150px", height: "150px" }}
              onClick={() => document.getElementById("profileImageInput").click()}
            />
            <input
              id="profileImageInput"
              type="file"
              hidden
              onChange={handleImageChange}
            />
          </div>
          <Row>
            <Col lg={2}></Col>

            <Col lg={8}>
              <Row>
                {refreshData && (
                  <div className="alert alert-success text-center">
                    {" "}
                    تم تحديث البيانات بنجاح
                  </div>
                )}

                <Col lg={12} md={12}>
                  <Form.Group controlId="formFirstName">
                    <Form.Label>الاسم *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ادخل اسمك الأول"
                      required
                      className=""
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={12} md={12}>
                  <Form.Group controlId="formFirstName">
                    <Form.Label>البريد الالكتروني</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ادخل البريد الالكتروني"
                      required
                      className=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col lg={12} md={12}>
                  <Form.Group controlId="formLastName">
                    <Form.Label>رقم الهاتف</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      placeholder="ادخل رقم الهاتف"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col lg={12} md={12}>
                  <Form.Group controlId="formLastName">
                    <Form.Label>المدينة *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ادخل المدينة"
                      required
                      value={address}
                      onChange={(e) => setAdress(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col
                  lg={6}
                  md={6}
                  className="d-lg-flex justify-content-lg-center"
                >
                  <Button
                    variant="link"
                    onClick={() =>
                      setPasswordFieldsVisible(!passwordFieldsVisible)
                    }
                    className="mb-3"
                  >
                    تغيير كلمة السر
                  </Button>
                </Col>

                {passwordFieldsVisible && (
                  <Row>
                    <Col
                      lg={6}
                      md={12}
                      className="d-lg-flex justify-content-lg-end"
                    >
                      <Form.Group controlId="formCurrentPassword">
                        <Form.Label>كلمة السر الحالية</Form.Label>
                        <Form.Control
                          type="password"
                          className="w-100"
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col
                      lg={6}
                      md={12}
                      className="d-lg-flex justify-lg-content-start"
                    >
                      <Form.Group controlId="formNewPassword">
                        <Form.Label>كلمة السر الجديدة</Form.Label>
                        <Form.Control
                          type="password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </Form.Group>
                    </Col>

                    <Col
                      lg={6}
                      md={12}
                      className="d-lg-flex justify-content-lg-end"
                    >
                      <Form.Group controlId="formConfirmNewPassword">
                        <Form.Label>تأكيد كلمة السر الجديدة</Form.Label>
                        <Form.Control
                          type="password"
                          value={confirmNewPassword}
                          onChange={(e) =>
                            setConfirmNewPassword(e.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      className="d-lg-flex justify-lg-content-start mt-3"
                    >
                      {errorMessage && (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      )}
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      className="d-lg-flex justify-content-lg-center mt-2 me-5"
                    >
                      <Button
                        variant="primary"
                        onClick={handlePasswordChange}
                        disabled={loading}
                      >
                        {loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "حفظ كلمة السر الجديدة"
                        )}
                      </Button>
                    </Col>
                  </Row>
                )}
                <Col
                  className="d-flex justify-content-center mt-5 mb-5"
                  lg={12}
                >
                  <Button
                    className="w-50"
                    variant="success"
                    onClick={saveProfile}
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "حفظ "
                    )}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col lg={2} md={2} sm={12}></Col>
          </Row>
        </Form>
      </Row>
    </Container>
  );
}

export default UserProfile;
