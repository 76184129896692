import axios from "axios";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import useUser from '../../hooks/useUser';

const CustomerInfoOrders = () => {
  const [orders, setOrders] = useState([]);
  const { companyId } = useParams();
  const { user ,isLoading } = useUser();

  const getOrders = async () => {
    try {
        const token = await user.getIdToken();
        const headers = token ? {authtoken:token} : {}
       
        const resp = await axios.get(`/api/customerdashboard/userdata`,{headers});
      const newOrders = resp.data.orders;
   
        const News = newOrders.filter(
          (order) => order.status === "استفسارات"
        );
        setOrders(News);
      
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    
    if(!isLoading) 
    getOrders();

  }, [isLoading]);


  return (
    <Container>
      {orders.map((order, index) => (
        <Link to={ `/customerdashboard/orderlist/orderdetails/${btoa(JSON.stringify(order._id))}`}>
          <Card className="my-3">
            <CardBody>
              <CardTitle>{order.customerName}</CardTitle>
              <Row>
                <Col sm={3}>
                  <CardText>
                    <strong>الايميل : </strong> {order.email}
                  </CardText>
                </Col>
                <Col sm={3}>
                  <CardText>
                    <strong>الطلب :</strong> {order.subService}
                  </CardText>
                </Col>
                <Col sm={3}>
                  <CardText>
                    <strong>حالة الطلب :</strong> {order.status}
                  </CardText>
                </Col>
                <Col sm={3}>
                  <CardText>
                    <strong>تاريخ نشر الطلب :</strong> {order.orderDate}
                  </CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Link>
      ))}
    </Container>
  );
};

export default CustomerInfoOrders;
