import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Button, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);

    {
      /* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */
    }

    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        e.target,
        "YOUR_PUBLIC_KEY"
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container text-lg-end">
          <div className="row">
            <div className="col-md-3 col-sm-5 col-md-offset-1 contact-info mx-5 ">
              <div className="contact-item">
                <h3>روابط</h3>
                <Nav.Link className="mx-2 mt-4" href="/">
                  الرئيسية
                </Nav.Link>
                <Nav.Link className="mx-2 mt-4" href="#features">
                  مميزاتنا
                </Nav.Link>
                <Nav.Link className="mx-2 mt-4" href="#about">
                  عن المنصة
                </Nav.Link>
                <Nav.Link className="mx-2 mt-4" href="#services">
                  خدماتنا
                </Nav.Link>
                <Link to={"/contact"}>
                  <Nav.Link className="mx-2 mt-4" href="#contact">
                    تواصل معنا
                  </Nav.Link>
                </Link>
              </div>
            </div>

            <div className="col-md-3 col-sm-5 col-md-offset-1 contact-info mx-5">
              <div className="contact-item">
                <h3>معلومات الاتصال</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> العنوان
                  </span>
                  {props.data ? props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> الهاتف
                  </span>{" "}
                  {props.data ? props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fas fa-envelope"></i> البريد الالكتروني
                  </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
            </div>

            <div className="col-md-4 text-end">
              <img
              style={{height: "90%", width: "120%"}}
                src={require("../assets/images/Logos/magal.png")}
                alt="logo"
              />
            </div>

            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a href={props.data ? props.data.facebook : "/"}>
                        <i
                          className="fab fa-facebook"
                          style={{ color: "#fafafa" }}
                        ></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.twitter : "/"}>
                        <i
                          className="fab fa-twitter"
                          style={{ color: "#fafafa" }}
                        ></i>
                      </a>
                    </li>
                    <li>
                      <i
                        className="fab fa-linkedin"
                        style={{ color: "#fafafa" }}
                      ></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 جميع الحقوق محفوظة لمنصة مجال البناء. بواسطة{" "}
            <a href="https://www.dafaalla.com" rel="nofollow">
              Dafa-Alla
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
