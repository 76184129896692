import React, { useEffect } from "react";
import WOW from "wowjs";
import { motion } from "framer-motion";

export const About = (props) => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const why = [
    "تكنولوجيا متقدمة",
    "توفير حلول مخصصة",
    "سهولة الاستخدام",
    "خبرة واحترافية",
  ];
  const why2 = ["توفير الجهد", "جودة الخدمة", "توفير الوقت ", " شركاء اكفاء"];

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <video width="100%" height="80%" controls>
              <source src="../assets/video/video.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2
                className={`wow animate__fadeInDown`}
                data-wow-offset="100"
                data-wow-duration={`2s`}
              >
                عن المنصة
              </h2>
              <p
                className={`wow animate__fadeInDown`}
                data-wow-offset="100"
                data-wow-duration={`2s`}
              >
                {props.data ? props.data.paragraph : "loading..."}
              </p>
              <h3
                className={`wow animate__fadeInDown`}
                data-wow-offset="100"
                data-wow-duration={`2s`}
              >
                لماذا تختارنا ؟
              </h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {why.map((d, i) => (
                      <li
                        key={`${d}-${i}`}
                        className={`wow animate__fadeInDown`}
                        data-wow-offset="300"
                        data-wow-duration={`${2 + i}s`}
                      >
                        {d}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {why2.map((d, i) => (
                      <li
                        key={`${d}-${i}`}
                        className={`wow animate__fadeInDown`}
                        data-wow-offset="200"
                        data-wow-duration={`${2 + i}s`}
                      >
                        {d}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
