import axios from "axios";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
  ProgressBar,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import useUser from "../../hooks/useUser";
import { jwtDecode } from "jwt-decode";
import NotFoundPage from "../NotFoundPage";

const OrdersFromReport = () => {
  const [orders, setOrders] = useState([]);
  const { user, isLoading } = useUser();
  const {type} = useParams()
  const [checkIsAdmin, setCheckIsAdmin] = useState(false);

  const getCookie = (name) => {
    const cookieValue = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
    return cookieValue ? cookieValue.pop() : '';
  };
   
  const dateFrom = getCookie('dateFrom');
  const dateTo = getCookie('dateTo');

  const isAdmin = async () => {
    try {
      const idTokenResult = await user.getIdTokenResult();
      // Check if the user has admin privileges
      return !!idTokenResult.claims.admin;
    } catch (error) {
      console.error("Error getting ID token:", error);
      return false;
    }
  };

  const getOrders = async () => {
    try {
      
        const token = await user.getIdToken();
        const headers = token ? { authtoken: token } : {};
        if(type === 'allorders'){
            const resp = await axios.post(`/api/dashboard/report`, { dateFrom, dateTo }, { headers });
            const decodedToken = jwtDecode(resp.data);
            setOrders(decodedToken.data);
        }else if(type === 'completeorders'){
            const resp = await axios.post(`/api/dashboard/report/completeorders`, { dateFrom, dateTo }, { headers });
            const decodedToken = jwtDecode(resp.data);
            setOrders(decodedToken.data);
        }else if(type === 'progressorders'){
            const resp = await axios.post(`/api/dashboard/report/inprogressorders`, { dateFrom, dateTo }, { headers });
            const decodedToken = jwtDecode(resp.data);
            setOrders(decodedToken.data);
        }else if(type === 'infoorders'){
            const resp = await axios.post(`/api/dashboard/report/infoorders`, { dateFrom, dateTo }, { headers });
            const decodedToken = jwtDecode(resp.data);
            setOrders(decodedToken.data);
        }
        
       
        
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
  };

  useEffect(() => {
   if (!isLoading && user) {
     isAdmin().then((isadmin) => {
        if (isadmin) {
          setCheckIsAdmin(true);
          getOrders();
        } else {
          setCheckIsAdmin(false);
        }
      });
    }
  }, [isLoading]);

  if (!checkIsAdmin && !isLoading) {
    return <NotFoundPage />;
  }


  return (
    <Container>
    <div> <h3>
    اجمالي الطلبات {
      type === 'allorders' ? 'كلها' :
      type === 'completeorders' ? 'المكتملة' :
      type === 'progressorders' ? 'قيد التنفيذ' :
      type === 'infoorders' ? 'قيد الاستفسارات' :
      ''
    } في الفترة ما بين {dateFrom} و {dateTo}
  </h3> </div>
      {orders &&
        orders.map((order, index) => (
          <Link
            key={index}
            to={{
              pathname: `/dashboard/order/${btoa(JSON.stringify(order._id))}`,
            }}
          >
            <Card className="my-3">
              <CardBody>
                <CardTitle>{order.customerName}</CardTitle>
                <Row>
                  <Col sm={4} md={6} className="mt-2">
                    <CardText>
                      <strong>الايميل : </strong> {order.email}
                    </CardText>
                  </Col>
                  <Col md={6} className="mt-2">
                    <CardText>
                      <strong>الطلب :</strong> {order.subService}
                    </CardText>
                  </Col>

                  <Col md={6} className="mt-3">
                    <CardText>
                      <strong>حالة الطلب :</strong> {order.status}
                    </CardText>
                  </Col>

                  <Col md={6} className="mt-3">
                    <CardText>
                      <strong>تاريخ نشر الطلب :</strong> {order.date}
                    </CardText>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Link>
        ))}
    </Container>
  );
};

export default OrdersFromReport;
