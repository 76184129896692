import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  ProgressBar,
  Row,
  Col,
  Form,
  Button,
  Image,
  Spinner,
} from "react-bootstrap";
import { Card, CardBody, CardTitle, CardText } from "react-bootstrap";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faPrint } from "@fortawesome/free-solid-svg-icons";
import Login from "../Login";
let variant = "";

const CompanyOrderDetails = () => {
  const { order_Id } = useParams();
  const [userProfile, setUserProfile] = useState("");
  const [orderDeatails, setOrderDetails] = useState([]);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const { user, isLoading } = useUser();
  const location = useLocation();
  const [selectedFileName, setSelectedFileName] = useState("");
  const inputFile = useRef(null);
  const textareaRef = useRef(null);

  const queryParams = new URLSearchParams(location.search);
  const encodedData = queryParams.get("id");
  const decodedOrder_id = JSON.parse(atob(order_Id));
  const navigate = useNavigate();

  const getOrder = async () => {
    try {
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

      const resp = await axios.post(
        `/api/companydashboard/orderlist/orderdetails`,
        { order_id: decodedOrder_id },
        { headers }
      );

      const resp2 = await axios.get("/api/users", { headers });
      setUserProfile(resp2.data);
      setOrderDetails(resp.data);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      getOrder();

      if (orderDeatails.progress <= 20) variant = "danger";
      else if (orderDeatails.progress > 21 && orderDeatails.progress <= 80)
        variant = "primary";
      else if (orderDeatails.progress > 80) variant = "success";
      console.log(orderDeatails.cusConfirmation);
    }
  }, [order_Id, isLoading]);

  const handIncresProgress = async () => {
    try {
      //console.log(order_Id)
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

      const resp = await axios.put(
        `/api/companydashboard/${decodedOrder_id}/incresprogress`,
        null,
        { headers }
      );
    } catch (error) {
      console.error("Error incrementing progress:", error);
    }
    getOrder();
  };

  const handeComplete = async () => {
    try {
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

      const resp = await axios.put(
        `/api/companydashboard/${decodedOrder_id}/complete`,
        null,
        { headers }
      );
      setOrderDetails(resp.data);
    } catch (error) {
      console.error("Error incrementing progress:", error);
    }
    getOrder();
  };

  const handeFirstAcc = async () => {
    try {
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

      const resp = await axios.put(
        `/api/companydashboard/${decodedOrder_id}/firstacc`,
        {
          Name: user.displayName,
        },
        { headers }
      );
    } catch (error) {
      console.error("Error incrementing progress:", error);
    }
    getOrder();
  };
  const handelAccept = async () => {
    try {
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

      const resp = await axios.put(
        `/api/compandashboard/${decodedOrder_id}/accept`,
        { companyName: user.displayName },
        { headers }
      );
    } catch (error) {
      console.error("Error incrementing progress:", error);
    }
    getOrder();
  };

  const ConversationBox = ({ senderName, message, img, profilePic }) => {
    return (
      <div className="conversation-box mt-3">
        <div className="sender-name">
          <img
          className="profile-pic"
            src={`https://magalaelbinaa.dafaalla.com/${profilePic}`}
            alt="profile"
          />
          <strong>{senderName}</strong>{" "}
        </div>
        <div className="message">{message}</div>
        <div>
          {img && (
            <a
              href={`https://magalaelbinaa.dafaalla.com/${img}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ cursor: "pointer" }}
                src={`https://magalaelbinaa.dafaalla.com/${img}`}
                alt="img"
                width={400}
                height={400}
              />{" "}
            </a>
          )}
        </div>
        <hr />
      </div>
    );
  };

  const handleSend = async () => {
    const formData = new FormData();
    formData.append("file", file); // إضافة الملف إلى مجموعة البيانات
    formData.append("Name", user.displayName); // إضافة اسم المستخدم
    formData.append("message", textareaRef.current.value); // إضافة الرسالة

    try {
      //console.log(order_Id);
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

      const resp = await axios.put(
        `/api/companydashboard/${order_Id}/sendmessage`,
        formData,
        {
          headers: {
            ...headers,
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      console.error("Error incrementing progress:", error);
    }

    getOrder();
    setMessage("");
    setFile(null);
    setSelectedFileName("");
    textareaRef.current.value = "";
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setSelectedFileName(file.name);
  };

  if (!isLoading) {
    if (!user) return navigate("/login");
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner animation="border" role="status">
          <span className="sr-only ">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if(!isLoading){
    if(!user)
    return(
      <Login />
    )
  }

  return (
    <Container className="mt-4" style={{ widt: "70%" }}>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          {orderDeatails && (
            <div>
              <Card>
                <div className="text-start">
                  <Button
                    variant="info"
                    style={{ width: "100px" }}
                    className="mt-2 mx-2 typing"
                    onClick={() => window.print()}
                  >
                    <FontAwesomeIcon icon={faPrint} size="lg" />
                    طباعة
                  </Button>
                </div>

                <CardBody className="print-section">
                  <CardTitle>{orderDeatails.customerName}</CardTitle>
                  <Row>
                    <Col sm={6}>
                      <CardText className="mt-3">
                        <strong>الطلب : </strong> {orderDeatails.subService}
                      </CardText>
                      <CardText className="mt-3">
                        <strong>الايميل :</strong> {orderDeatails.email}
                      </CardText>
                      <CardText className="mt-3">
                        <strong>رقم الهاتف :</strong>{" "}
                        {orderDeatails.phoneNumber}
                      </CardText>
                      <CardText className="mt-3">
                        <strong>حالة الطلب :</strong> {orderDeatails.status}
                      </CardText>
                      <CardText className="mt-3">
                        <strong>سعر مزود الخدمة :</strong>{" "}
                        {orderDeatails.CompanyPrice}
                      </CardText>
                      <CardText className="mt-3">
                        <strong>تاريخ نشر الطلب :</strong>{" "}
                        {orderDeatails.orderDate}
                      </CardText>
                    </Col>
                    <Col sm={6}>
                      <CardText className="mt-3">
                        <strong>المدينة :</strong> {orderDeatails.city}
                      </CardText>
                      <CardText>
                        <strong>التاريخ:</strong> {orderDeatails.date}
                      </CardText>
                      <CardText>
                        <strong>الوقت:</strong> {orderDeatails.time}
                      </CardText>
                      <CardText>
                        <strong> طريقة الدفع : </strong>{" "}
                        {orderDeatails.paymentMethod}
                      </CardText>
                      <CardText>
                        <strong> سعر العميل : </strong>{" "}
                        {orderDeatails.cutomerPrice
                          ? orderDeatails.cutomerPrice
                          : "لم يحدد  سعر و اعتمد على سعرك"}
                      </CardText>
                      {orderDeatails.status === "استفسارات" && (
                        <div className="alert-danger" role="alert">
                          <CardText>
                            {orderDeatails.cusConfirmation ? (
                              <strong>
                                {" "}
                                تأكد جيداََ من متلطبات العميل قبل البدء في تنفيذ
                                الخدمة
                              </strong>
                            ) : (
                              <strong>
                                {" "}
                                انتظر موافقة العميل النهائية بعد الاستفسارات
                                لتبدأ بتنفيذ الخدمة
                              </strong>
                            )}
                          </CardText>
                        </div>
                      )}
                      {orderDeatails.status === "قيد التنفيذ" && (
                        <div>
                          <strong> مستوى التقدم: </strong>
                          <ProgressBar
                            fade
                            now={orderDeatails.progress}
                            label={`${orderDeatails.progress}%`}
                            style={{ height: "30px" }}
                            variant={variant}
                          />
                        </div>
                      )}
                    </Col>

                    {orderDeatails.status === "جديدة" && (
                      <div className="alert alert-success text-center mt-3">
                        عند القبول المبدأي للطلب تفتح نافذة الرسائل للنقاش حول
                        الخدمة او التفاوض
                      </div>
                    )}
                    {orderDeatails.CompanyComplete === true &&
                      orderDeatails.custConfComplete === false && (
                        <div className="alert alert-success text-center mt-3">
                          لقد قمت بتأكيد اكمال المهمة انتظر تأكيد العميل و سيتم
                          اغلاق نافذة الرسائل بعدها
                        </div>
                      )}

                    {orderDeatails.status === "قيد التنفيذ" && (
                      <div>
                        {orderDeatails.progress < 100 ? (
                          <Button
                            onClick={handIncresProgress}
                            variant="primary"
                            type="submit"
                            className="w-100 mt-4"
                          >
                            زيادة نسبة التقدم ب 10%
                          </Button>
                        ) : (
                          <Button
                            onClick={handeComplete}
                            variant="success"
                            type="submit"
                            className="w-100 mt-4"
                            disabled={orderDeatails.CompanyComplete}
                          >
                            تحويل حالة الخدمة الى مكتملة
                          </Button>
                        )}
                      </div>
                    )}

                    {orderDeatails.paymentMethod === "تحويل بنكي" && (
                      <div className="alert alert-info mt-4 text-center ">
                        {" "}
                        تأكد من ارفاق العميل لإشعار التحويل البنكي في الرسائل
                      </div>
                    )}

                    {orderDeatails.CompanyComplete === "مكتملة" && (
                      <div>
                        <Button
                          disabled
                          variant="success"
                          type="submit"
                          className="w-100 mt-4"
                        >
                          تم انجاز الخدمة
                        </Button>
                      </div>
                    )}
                    {orderDeatails.status === "جديدة" && (
                      <div>
                        <Button
                          onClick={handeFirstAcc}
                          variant="success"
                          type="submit"
                          className="w-100 mt-4"
                        >
                          القبول المبدأي للطلب
                        </Button>
                      </div>
                    )}
                    {orderDeatails.status === "استفسارات" && (
                      <div>
                        <Button
                          disabled={!orderDeatails.cusConfirmation}
                          onClick={handelAccept}
                          variant="primary"
                          type="submit"
                          className="w-100 mt-4"
                        >
                          البدء في تنفيذ الطلب
                        </Button>
                      </div>
                    )}
                  </Row>
                </CardBody>
              </Card>
              <Row className="mt-4">
                {orderDeatails.status === "استفسارات" ||
                orderDeatails.status === "قيد التنفيذ" ? (
                  <Card style={{ boxShadow: "none" }}>
                    <CardBody>
                      <CardTitle className="mb-5">الرسائل</CardTitle>
                      {orderDeatails.messages &&
                        orderDeatails.messages
                          .filter(
                            (message) =>
                              message.resciverId === user.uid ||
                              message.senderId === user.uid
                          )
                          .map((message, i) => (
                            <ConversationBox
                              key={i}
                              senderName={message.Name}
                              message={message.message}
                              img={message.fileUrl}
                              profilePic={userProfile.profileImage}
                            />
                          ))}
                      <div>
                        <Row>
                          <p>اضف رسالة او رد على رسالة</p>
                        </Row>
                        <Row>
                          <textarea
                            col={5}
                            row={20}
                            ref={textareaRef} // إضافة العنصر المرجعي هنا
                            required
                          />
                        </Row>
                        <Row>
                          <Button
                            onClick={handleSend}
                            variant="primary"
                            type="submit"
                            className="mt-4 w-25"
                          >
                            أرسل
                          </Button>

                          <input
                            type="file"
                            onChange={handleFileChange}
                            disabled={!message && !orderDeatails.messages}
                            style={{ display: "none" }} // إخفاء العنصر الحقيقي
                            ref={inputFile} // الإشارة إلى العنصر الحقيقي باستخدام useRef
                          />

                          <Button
                            variant="info"
                            style={{ width: "60px" }}
                            className="mt-4 mx-2"
                            onClick={() => inputFile.current.click()} // النقر على الزر يؤدي إلى النقر على العنصر الحقيقي
                          >
                            <FontAwesomeIcon icon={faFile} size="lg" />
                          </Button>
                          {selectedFileName}
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                ) : null}
              </Row>
            </div>
          )}
        </Col>
        <Col md={1}></Col>
      </Row>
    </Container>
  );
};

export default CompanyOrderDetails;

/*

{orderDeatails.map((orderDeatails)=> (
        <Card>
        <CardBody>
          <CardTitle>{orderDeatails.userName}</CardTitle>
          <Row>
            <Col sm={6}>
              <CardText>
                <strong>الطلب : </strong> {orderDeatails.subService}
              </CardText>
              <CardText>
                <strong>الايميل :</strong> {orderDeatails.email} 
              </CardText>
              <CardText>
                <strong>رقم الهاتف :</strong> {orderDeatails.phoneNumber}
              </CardText>
            </Col>
            <Col sm={6}>
            <CardText>
            <strong>المدينة :</strong> {orderDeatails.city}
          </CardText>
              <CardText>
                <strong>الموقع :</strong> {orderDeatails.mapSrc}
              </CardText>
              <CardText>
                <strong>التاريخ:</strong> {orderDeatails.date}
              </CardText>
              <CardText>
                <strong>الوقت:</strong> {orderDeatails.time}
              </CardText>
            </Col>
          </Row>
        </CardBody>
      </Card>
     ))}


       {orderDeatails.map((orderDeatails)=> (
        <Card>
        <CardBody>
          <CardTitle>{orderDeatails.userName}</CardTitle>
          <Row>
            <Col sm={6}>
              <CardText>
                <strong>الطلب : </strong> {orderDeatails.subService}
              </CardText>
              <CardText>
                <strong>الايميل :</strong> {orderDeatails.email} 
              </CardText>
              <CardText>
                <strong>رقم الهاتف :</strong> {orderDeatails.phoneNumber}
              </CardText>
            </Col>
            <Col sm={6}>
            <CardText>
            <strong>المدينة :</strong> {orderDeatails.city}
          </CardText>
              <CardText>
                <strong>الموقع :</strong> {orderDeatails.mapSrc}
              </CardText>
              <CardText>
                <strong>التاريخ:</strong> {orderDeatails.date}
              </CardText>
              <CardText>
                <strong>الوقت:</strong> {orderDeatails.time}
              </CardText>
            </Col>
          </Row>
        </CardBody>
      </Card>
     ))}



     <CardText className='mt-3'>
                <strong>الموقع :</strong> {orderDeatails.mapSrc}
              </CardText>
*/
