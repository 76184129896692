import axios from "axios";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import reviceId from "./Messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { MDBBadge, MDBIcon } from "mdb-react-ui-kit";
import useUser from "../../hooks/useUser";

const CutomerOrderList = () => {
  const [orders, setOrders] = useState([]);
  const { cutomerId } = useParams();
  const { user, isLoading } = useUser();
  const [notReadOrNum, setNotReadOrNum] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const getOrders = async () => {
      try {
        const token = await user.getIdToken();
        const headers = token ? { authtoken: token } : {};

        const resp = await axios.get(`/api/customerdashboard/orderslist`, {
          headers,
        });
        const newOrders = resp.data;
        setOrders(resp.data);
        const numNewConfirm = resp.data.filter(
          (order) => order.isConfirm && !order.isUserReadConfirm
        ).length;
        setNotReadOrNum(numNewConfirm);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    if (!isLoading && user) {
      getOrders();
    }
  }, [isLoading]);

  if (!isLoading) {
    if (!user) return navigate("/customerlogin");
  }

  return (
    <Container>
      {orders.map((order, index) =>
        order.companyAccept.map((company) => (
          <Link
            to={{
              pathname: `/customerdashboard/orderlist/${btoa(
                JSON.stringify(order._id)
              )}`,
              search: `id=${btoa(JSON.stringify(company.companyId))}`,
              state: { companyName: company.Name}, // إضافة companyName إلى state
            }}
          >
            <Card className="my-3">
              <CardBody>
                <CardTitle>
                  <Row>
                    <Col>
                      <span>تم القبول بواسطة : </span> {company.Name}
                    </Col>
                    <Col className="text-start">
                      <a href="#!" style={{ marginRight: "5px" }}>
                        <MDBIcon fas icon="envelope" size="lg" />
                        {(order.messages.filter(
                          (message) =>
                            message.isReaded === false && !message.resciverId
                        ).length !== 0 ||
                          notReadOrNum !== 0) && (
                          <MDBBadge
                            color={
                              order.isUserReadConfirm ||
                              (order.messages.filter(
                                (message) =>
                                  message.isReaded === false &&
                                  !message.resciverId
                              ).length === 0
                                ? false
                                : true)
                                ? "primary"
                                : "danger"
                            }
                            notification
                            pill
                          >
                            {order.messages.filter(
                              (message) =>
                                message.isReaded === false &&
                                !message.resciverId
                            ).length + (order.isUserReadConfirm ? 0 : 1)}
                          </MDBBadge>
                        )}
                      </a>
                    </Col>
                  </Row>
                </CardTitle>
                <Row>
                  <Col sm={6}>
                    <CardText>
                      <strong>الطلب :</strong> {order.subService}
                    </CardText>
                  </Col>
                  <Col sm={6}>
                    <CardText>
                      <strong>حالة الطلب :</strong> {order.status}
                    </CardText>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Link>
        ))
      )}
    </Container>
  );
};

export default CutomerOrderList;
