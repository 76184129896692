import {
    faServer,
    faEdit,
    faTrash,
    faPlus,
  } from "@fortawesome/free-solid-svg-icons";
  import { Card, ListGroup, Row, Col, Button } from "react-bootstrap";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { useState, useEffect } from "react";
  import { useParams, useNavigate } from "react-router-dom";
  import axios from "axios";
  import { Container, Form, FormControl, Modal } from "react-bootstrap";
  import { MDBInputGroup, MDBBtn } from "mdb-react-ui-kit";
  import { CurtainsOutlined } from "@mui/icons-material";
  import useUser from "../../hooks/useUser";
  import WaitingApprove from "../WaitingApproval";
  import Login from "../Login";
  
  const Services = () => {
    const [serviceList, setServiceList] = useState([]);
    const [serviceCategory, setServiceCategory] = useState([]);
    const [subServToCheck, setSubServToCheck] = useState([]);
    const [filteredServiceList, setFilteredServiceList] = useState([]);
    const [addServModal, setAddServModal] = useState(false);
    const [addSubServModal, setAddSubServModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleCategoryModal, setDeleCategoryModal] = useState(false);
    const [addFromPlatModal, setAddFromPlatModal] = useState(false);
    const [isAdminSer, setisAdminSer] = useState([]); // to check deleted services if it from admin
    const [addserviceName, setAddserviceName] = useState("");
    const [addserviceList, setAddserviceList] = useState([]);
    const [addservlistName, setAddservlistName] = useState("");
    const [subServPrice, setSubServPrice] = useState("");
    const [errorService, setErrorService] = useState("");
    const [SeviceName, setSaviceName] = useState("");
    const [subSerName, setSubSerName] = useState("");
    const [CatName, setCatName] = useState("");
    const [addSubSerError, setAddSubSerError] = useState("");
    const [companyProfile,setCompanyProfile] = useState([])
    const [isConfermed , setIsConfermed] =  useState(true) 
    
    const { user, isLoading } = useUser();
    const navigate = useNavigate();
  
    const getData = async () => {
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};
  
      const resp = await axios.get(`/api/dashboard/services`, { headers });
      console.log(resp.data);
      setServiceList(resp.data);
      setServiceCategory(resp.data.Services);
    
  
      // console.log(resp.data.serviceList);
    };
  
    useEffect(() => {
      if (!isLoading && user) getData();
    }, [isLoading]);
  
    const edit = (e) => {
      e.preventDefault();
      console.log(e);
    };
    const handleShow = () => setAddServModal(true);
    const handleShowAddFromPlatModal = () => setAddFromPlatModal(true);
    const handlShowSubMod = (subservname) => {
      setSaviceName(subservname);
      //console.log(SeviceName)
      setAddSubServModal(true);
    };
  
    const handleShowDeleteModal = (Name, subName, isAdmin) => {
      setSaviceName(Name);
      setSubSerName(subName);
      setDeleteModal(true);
      setisAdminSer(isAdmin);
    };
    useEffect(() => {
      //console.log(isAdminSer)
    }, [isAdminSer]);
    const handleShowDeleCategoryModal = (Name) => {
      setSaviceName(Name);
      setDeleCategoryModal(true);
    };
  
    const handleCheckboxChange = (subSerName) => {
      setFilteredServiceList((prevFilteredServiceList) =>
        prevFilteredServiceList.map((subserv) =>
          subserv.SubService === subSerName
            ? { ...subserv, checked: !subserv.checked }
            : subserv
        )
      );
    };
  
    useEffect(() => {
      const updatedSelectedServices = filteredServiceList.filter(
        (service) => service.checked
      );
      setSubServToCheck(updatedSelectedServices);
      // console.log(updatedSelectedServices)
    }, [filteredServiceList]);
  
    const handleClose = () => {
      setAddServModal(false);
      setAddserviceList([]);
      setSaviceName([]);
    };
    const handleCloseSubSerModal = () => {
      setAddSubServModal(false);
      setAddserviceList([]);
    };
    const handleCloseDeleteModal = () => {
      setDeleteModal(false);
    };
    const handleCloseDeleCategoryModal = () => {
      setDeleCategoryModal(false);
    };
    const handleCloseAddFromPlatModal = () => {
      setAddFromPlatModal(false);
      setSaviceName("");
      setCatName("");
    };
  
    const AddServicesHandeler = async () => {
      try {
        const token = await user.getIdToken();
        const headers = token ? { authtoken: token } : {};
        if(addServModal)
        if(!addserviceName){
         setAddSubSerError("يجب ملء جميع الحقول")
         return
        }
        
        const resp = await axios.post(
          `/api/dashboard/services/addservice`,
          {
            ServiceName: addserviceName,
            ServiceList: addserviceList,
          },
          { headers }
        );
        //console.log(resp.data)
      } catch (e) {
        setErrorService(e.message);
      }
  
      getData();
  
      setAddserviceName("");
      handleClose();
    };
  
   
  
    const AddSubServicesHandeler = async () => {
      try {
        const token = await user.getIdToken();
        const headers = token ? { authtoken: token } : {};
  
        const resp = await axios.post(
          `/api/dashboard/addsubservices`,
          {
            ServiceName: SeviceName,
            ServiceList: addserviceList,
          },
          { headers }
        );
  
        handleCloseSubSerModal();
      } catch (e) {
        console.log(e.message);
      }
  
      getData();
    };
  
    const handleDeleteSubServ = async () => {
      try {
        const token = await user.getIdToken();
        const headers = token ? { authtoken: token } : {};
  
        const resp = await axios.post(
          `/api/dashboard/services/deletesubservice`,
          {
            ServiceName: SeviceName,
            subSerName: subSerName,
          },
          { headers }
        );
        handleCloseDeleteModal();
      } catch (e) {
        console.log(e.message);
      }
  
      getData();
    };
  
    const handleDeleteServ = async () => {
      try {
        const token = await user.getIdToken();
        const headers = token ? { authtoken: token } : {};
  
        const resp = await axios.post(
          `/api/dashboard/services/deleteservice`,
          {
            ServiceName: SeviceName,
          },
          { headers }
        );
        if (resp) {
          handleCloseDeleCategoryModal();
        }
      } catch (e) {
        console.log(e.message);
      }
  
      getData();
    };
  
    const handleSelectService = (e) => {
      setCatName(e.target.value);
      const selectedService = serviceCategory.find(
        (service) => service.ServiceName === e.target.value
      );
      setFilteredServiceList(selectedService.ServiceList);
    };
    function addServiceTolist() {
      
      if(addServModal)
       if(!addserviceName){
        setAddSubSerError("يجب ملء جميع الحقول")
        return
       }
      
      if (!addservlistName || !subServPrice) {
        setAddSubSerError("الرجاء كتابة اسم الخدمة و سعرها اولا");
      } else {
        const newService = {
          ProviderId: user.uid,
          SubService: addservlistName,
          price: subServPrice,
          subListServiceProvider:[{_id : user.uid, Name:user.displayName , price: subServPrice,}]
        };
        
        setAddserviceList([...addserviceList, newService]);
        setAddservlistName("");
        setSubServPrice("");
        setAddSubSerError("");
      }
    }
  
    if(!isLoading){
      if(!user)
      return(
       <Login />
      )
    }
  
  
    return (
      <div className="container">
        {/* Col 1 services */}
        <div>
          <Row>
            <div>
              <Row>
                <h1>الخدمات</h1>
                <Col>
                  <p>عرض معلومات حول الخدمات التي تقدمها المنصة و التعديل عليها.</p>
                </Col>
                <Col md={4}>
                  <Row>
                    <Col>
                      <Button
                        variant="primary"
                        onClick={handleShow}
                        className="w-100 mb-3"
                      >
                        اضافة خدمة
                      </Button>
                    </Col>
                    
                  </Row>
                </Col>
              </Row>
            </div>
  
            <div>
              {serviceList.map((service, i) => (
                <Card className="mt-2" key={i}>
                  <Card.Header>
                    <Row>
                      <Col lg={10} md={10} xs={10}>
                        <FontAwesomeIcon icon={faServer} /> {service.ServiceName}
                      </Col>
                      <Col lg={2} md={2} xs={2} className="text-start ml-2">
                        <FontAwesomeIcon
                          onClick={() =>
                            handleShowDeleCategoryModal(service.ServiceName)
                          }
                          icon={faTrash}
                          className="text-danger"
                          size="lg"
                          style={{ cursor: "pointer" }}
                        />
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <ListGroup>
                      {service.ServiceList && service.ServiceList.map((subserv, j) => (
                        <ListGroup.Item key={subserv.ProviderId + j}>
                          <Row>
                            <Col lg={8} md={8} xs={6}>
                              {subserv.SubService}
                            </Col>
                            <Col lg={2} md={2} xs={2}>
                              {subserv.price}{" "}
                            </Col>
                            <Col className="text-start" lg={2} md={2} xs={4}>
                              {/*
                          <FontAwesomeIcon
                            onClick={() => edit(subserv)}
                            icon={faEdit}
                            className=" text-primary mx-3"
                            size="lg"
                            style={{ cursor: "pointer" }}
                          />
                  */}
                              <FontAwesomeIcon
                                onClick={() =>
                                  handleShowDeleteModal(
                                    service.ServiceName,
                                    subserv.SubService,
                                    subserv.subListServiceProvider
                                  )
                                }
                                icon={faTrash}
                                className="text-danger"
                                size="lg"
                                style={{ cursor: "pointer" }}
                              />
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                      <ListGroup.Item>
                        <Row
                          style={{ cursor: "pointer" }}
                          onClick={() => handlShowSubMod(service.ServiceName)}
                        >
                          <Col lg={10} md={10} xs={8}>
                            <strong>اضافة خدمة فرعية جديدة الى هذة الفئة </strong>
                          </Col>
                          <Col className="text-start" lg={2} md={2} xs={4}>
                            <FontAwesomeIcon
                              icon={faPlus}
                              className=" text-primary"
                              size="lg"
                              style={{ cursor: "pointer" }}
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </Row>
        </div>
        {/* End Col 1 services */}
  
        {/* Add new service modal */}
        <div>
          <Modal show={addServModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>اضافة خدمة جديدة</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
              {addSubSerError && (
                <div className="alert alert-danger">{addSubSerError}</div>
              )}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>اسم الخدمة</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={addserviceName}
                    onChange={(e) => setAddserviceName(e.target.value)}
                  />
                </Form.Group>
              </Form>
  
              <Form.Label>اكتب الخدمات الفرعية</Form.Label>
              <MDBInputGroup className="mb-3">
                <input
                  className="form-control w-75"
                  placeholder="اكتب اسم الخدمة ثم اضغغط + للاضافة"
                  type="text"
                  value={addservlistName}
                  onChange={(e) => setAddservlistName(e.target.value)}
                />
                <input
                  className="form-control w-25 mx-2"
                  placeholder="السعر"
                  type="text"
                  value={subServPrice}
                  onChange={(e) => setSubServPrice(e.target.value)}
                />
                <MDBBtn outline onClick={() => addServiceTolist()}>
                  <FontAwesomeIcon
                    icon={faPlus}
                    className=" text-primary"
                    size="lg"
                    style={{ cursor: "pointer" }}
                  />
                </MDBBtn>
              </MDBInputGroup>
              {addserviceList.map((service, i) => (
                <ListGroup.Item key={i}>
                {service.SubService} <br></br>
                {service.price}
                </ListGroup.Item>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="px-4 w-50"
                variant="primary"
                onClick={AddServicesHandeler}
              >
                اضافة
              </Button>
              <Button variant="secondary" onClick={handleClose}>
                الغاء
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/*End Add new service modal */}
  
       
  
        {/* Add subervice modal */}
        <div>
          <Modal show={addSubServModal} onHide={handleCloseSubSerModal}>
            <Modal.Header closeButton>
              <Modal.Title>اضافة خدمات فرعية جديدة</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {addSubSerError && (
                <div className="alert alert-danger">{addSubSerError}</div>
              )}
              <Form.Label>اكتب اسم الخدمات الفرعية</Form.Label>
              <MDBInputGroup className="mb-3">
                <input
                  className="form-control w-75"
                  placeholder="اكتب اسم الخدمة الفرعية"
                  type="text"
                  value={addservlistName}
                  onChange={(e) => setAddservlistName(e.target.value)}
                />
                <input
                  className="form-control w-25 mx-2"
                  placeholder="السعر"
                  type="text"
                  value={subServPrice}
                  onChange={(e) => setSubServPrice(e.target.value)}
                />
                <MDBBtn outline onClick={() => addServiceTolist()}>
                  <FontAwesomeIcon
                    icon={faPlus}
                    className=" text-primary"
                    size="lg"
                    style={{ cursor: "pointer" }}
                  />
                </MDBBtn>
              </MDBInputGroup>
              {addserviceList.map((service, i) => (
                <ListGroup.Item key={i}>
                  {service.SubService} <br></br>
                  {service.price}
                </ListGroup.Item>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="px-4 w-50"
                variant="primary"
                onClick={AddSubServicesHandeler}
              >
                اضافة
              </Button>
              <Button variant="secondary" onClick={handleCloseSubSerModal}>
                الغاء
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/*End Add new service modal */}
  
        {/* delete sub modal */}
        <div>
          <Modal show={deleteModal} onHide={handleCloseDeleteModal}>
            <Modal.Header closeButton>
              <Modal.Title>حذف خدمة</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="alert alert-danger" role="alert">
                هل أنت متأكد من حذف هذة الخدمة
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="px-4 w-50"
                variant="danger"
                onClick={handleDeleteSubServ}
              >
                حذف
              </Button>
              <Button variant="secondary" onClick={handleCloseDeleteModal}>
                الغاء
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/*End delete sub modal */}
  
        {/* delete Category modal */}
        <div>
          <Modal show={deleCategoryModal} onHide={handleCloseDeleCategoryModal}>
            <Modal.Header closeButton>
              <Modal.Title>حذف خدمة</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="alert alert-danger" role="alert">
                هل أنت متأكد من حذف هذة الفئة , سيتم حذف جميع الخدمات التي تندرج
                تحتها
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="px-4 w-50"
                variant="danger"
                onClick={handleDeleteServ}
              >
                حذف
              </Button>
              <Button variant="secondary" onClick={handleCloseDeleCategoryModal}>
                الغاء
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/*End delete Category modal */}
      </div>
    );
  };
  
  export default Services;
  