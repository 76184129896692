import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Container,
  ProgressBar,
  Row,
  Col,
  Form,
  Button,
  Image,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardTitle, CardText } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useUser from "../../hooks/useUser";

let variant = "";
const CustomerOrderDetails = () => {
  const { order_Id } = useParams();
  const [orderDeatails, setOrderDetails] = useState([]);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const { user, isLoading } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const decodedData = JSON.parse(atob(order_Id));
    const orderId = decodedData;

    const getOrder = async () => {
      try {
        const token = await user.getIdToken();
        const headers = token ? { authtoken: token } : {};

        const resp = await axios.post(
          `/api/customer/orderlist/orderdetails/${orderId}`,
          null,
          { headers }
        );
        setOrderDetails(resp.data);
        console.log(resp.data);
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };
    if (!isLoading && user) {
      getOrder();
    }
  }, [order_Id, isLoading]);

  const CompanyAccept = ({ Name }) => {
    return (
      <div className="conversation-box mt-3">
        <div className="sender-name">
          تم القبول بواسطة <strong>{Name}</strong>{" "}
        </div>
        <hr />
      </div>
    );
  };

  if (!isLoading) {
    if (!user) return navigate("/customerlogin");
  }

  return (
    <Container className="mt-4" style={{ widt: "70%" }}>
      <Card>
        <CardBody>
          <CardTitle>{orderDeatails.userName}</CardTitle>
          <Row>
            <Col sm={6}>
              <CardText className="mt-3">
                <strong>الطلب : </strong> {orderDeatails.subService}
              </CardText>
              <CardText className="mt-3">
                <strong>الايميل :</strong> {orderDeatails.email}
              </CardText>
              <CardText className="mt-3">
                <strong>رقم الهاتف :</strong> {orderDeatails.phoneNumber}
              </CardText>
              <CardText className="mt-3">
                <strong>حالة الطلب :</strong> {orderDeatails.status}
              </CardText>
              <CardText className="mt-3">
                <strong>سعر مزود الخدمة :</strong> {orderDeatails.CompanyPrice}
              </CardText>
              <CardText className="mt-3">
                <strong>تاريخ نشر الطلب :</strong> {orderDeatails.orderDate}
              </CardText>
            </Col>
            <Col sm={6}>
              <CardText className="mt-3">
                <strong>المدينة :</strong> {orderDeatails.city}
              </CardText>
              <CardText>
                <strong>التاريخ:</strong> {orderDeatails.date}
              </CardText>
              <CardText>
                <strong>الوقت:</strong> {orderDeatails.time}
              </CardText>
              <CardText>
                <strong> طريقة الدفع : </strong> {orderDeatails.paymentMethod}
              </CardText>
              <CardText>
                <strong> سعرك : </strong>{" "}
                {orderDeatails.cutomerPrice
                  ? orderDeatails.cutomerPrice
                  : "لم تحدد  سعر و اعتمدت سعر مزود الخدمة"}
              </CardText>
              {orderDeatails.status === "جديدة" && (
                <div className="alert-info" role="alert">
                  <CardText>
                    <strong> في انتظار موافقة أحد مزودي الخدمات</strong>
                  </CardText>
                </div>
              )}
              {orderDeatails.status === "استفسارات" && (
                <div>
                  <div className="alert-danger" role="alert">
                    <CardText>
                      {!orderDeatails.cusConfirmation && (
                        <strong>
                          {" "}
                          هذة مساحة للنقاش حول الخدمة قبل البدء يمكنك مناقشة
                          مزود الخدمة و بعد التأكد طلبك اضغط على تأكيد الطلب
                        </strong>
                      )}
                    </CardText>
                  </div>
                  <div className="alert-success" role="alert">
                    <CardText>
                      {orderDeatails.cusConfirmation && (
                        <strong>
                          {" "}
                          لقد تم تأكيد طلبك سيبدأ مزود الخدمة في التنفيذ
                        </strong>
                      )}
                    </CardText>
                  </div>
                </div>
              )}
              {orderDeatails.status === "قيد التنفيذ" && (
                <div>
                  <strong> مستوى التقدم: </strong>
                  <ProgressBar
                    fade
                    now={orderDeatails.progress}
                    label={`${orderDeatails.progress}%`}
                    style={{ height: "30px" }}
                    variant={variant}
                  />
                </div>
              )}
            </Col>

            {orderDeatails.status === "مكتملة" &&
              orderDeatails.custConfComplete && (
                <div>
                  <Button
                    disabled
                    variant="success"
                    type="submit"
                    className="w-100 mt-4"
                  >
                    تم انجاز الخدمة
                  </Button>
                </div>
              )}
            {orderDeatails.status === "قيد التنفيذ" &&
              orderDeatails.CompanyComplete && (
                <div>
                  <Button
                    disabled
                    variant="success"
                    type="submit"
                    className="w-100 mt-4"
                  >
                    تأكيد انجاز الخدمة
                  </Button>
                </div>
              )}
          </Row>
        </CardBody>
      </Card>
      <Row className="mt-4">
        {orderDeatails.status === "استفسارات" ||
        orderDeatails.status === "قيد التنفيذ" ? (
          <Card style={{ boxShadow: "none" }}>
            <CardBody>
              <CardTitle className="mb-5">
                مزودي الخدمات الذين قبلوا الطلب
              </CardTitle>
              {orderDeatails.companyAccept &&
                orderDeatails.companyAccept.map((company, i) => (
                  <Link
                    to={{
                      pathname: `/customerdashboard/orderlist/${order_Id}`,
                      search: `?id=${btoa(
                        JSON.stringify(company.companyId)
                      )}&Name=${company.Name}`,
                    }}
                    key={i}
                  >
                    تم القبول بواسطة <strong>{company.Name}</strong>
                    <hr />
                  </Link>
                ))}
            </CardBody>
          </Card>
        ) : null}
      </Row>
    </Container>
  );
};

export default CustomerOrderDetails;
