import React ,{useEffect} from "react";
import WOW from 'wowjs';
export const Services = (props) => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const services = [
    {
      "icon": "fa fa-certificate fa-3x",
      "name": "خدمات الرخص الإنشائية",
      "text": "خدمات الرخص الإنشائية: تتضمن إصدار رخص البناء وتعديلها وتجديدها بمهنية ودقة عالية."
    },
    {
      "icon": "fa fa-cart-arrow-down fa-3x",
      "name": "خدمات الرخص التجارية",
      "text": "خدمات الرخص التجارية: نقدم خدمات إصدار وتعديل رخص النشاط التجاري بشكل موثوق وفعّال."
    },
    {
      "icon": "fa fa-cloud-download fa-3x",
      "name": "خدمات الحفريات",
      "text": "خدمات الحفريات: توفير خدمات فحص وتقييم التربة وإصدار التقارير الهندسية اللازمة للحفريات."
    },
    {
      "icon": "fa fa-building fa-3x",
      "name": "إصدار رخصة البناء",
      "text": "إصدار رخصة البناء: نقدم خدمات إصدار رخص البناء بمواصفات متقدمة وبمهنية عالية."
    },
    {
      "icon": "fa fa-sync fa-3x",
      "name": "تجديد رخصة البناء",
      "text": "تجديد رخصة البناء: نقدم خدمات تجديد رخص البناء بشكل سريع وفعّال."
    },
    {
      "icon": "fa fa-pencil fa-3x",
      "name": "تعديل رخصة نشاط تجاري",
      "text": "تعديل رخصة نشاط تجاري: نقدم خدمات تعديل رخص النشاط التجاري بمرونة وفقًا لاحتياجات العملاء."
    }
]

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>ابرز خدماتنا</h2>
          <p>
          نقدم مجموعة شاملة من الخدمات المتخصصة والموثوقة في مجالات البناء، الهندسة، الفحص، وتوريد مواد البناء، لتلبية احتياجاتكم بكفاءة واحترافية عالية
          </p>
        </div>
        <div className="row">
          {services.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4 wow animate__fadeInUp"  data-wow-offset="300" data-wow-duration={`${2 + i}s`}>
                  {" "}
                  <i className={d.icon} ></i>
                  <div className="service-desc ">
                    <h3>{d.name}</h3>
                    <p className="text-justify">{d.text}</p>
                  </div>
                </div>
              ))
            }
        </div>
      </div>
    </div>
  );
};
