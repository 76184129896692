import React ,{useEffect} from "react";
import WOW from 'wowjs';

export const Features = (props) => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const features= [
    {
      "icon": "fa fa-building fa-3x",
      "title": "توفير خدمات متعددة",
      "text": "نقدم مجموعة شاملة من الخدمات في مجالات البناء والهندسة والفحص، مما يوفر لعملائنا الراحة والسهولة في الحصول على كافة الخدمات التي يحتاجون إليها في مكان واحد."
    },
    {
      "icon": "fa fa-dollar-sign fa-3x",
      "title": "تكلفة مناسبة وشفافية",
      "text": "نقدم الخدمات بتكلفة مناسبة ومنافسة، مع ضمان الشفافية في عملية التسعير لضمان رضا عملائنا وتلبية احتياجاتهم بشكل كامل."
    },
    {
      "icon": "fa fa-headset fa-3x",
      "title": "خدمة عملاء ممتازة",
      "text": "نوفر خدمة عملاء ممتازة، حيث يتمتع فريق الدعم بالخبرة والاحترافية في التعامل مع جميع استفسارات العملاء وحل جميع المشكلات بفعالية وسرعة."
    }
]

  return (
    <div id="features" className="text-center">
    <div className="container">
      <div className="section-title">
        <h2 className=" text-center wow animate__fadeIn"  data-wow-offset="100" data-wow-duration="1s">مميزاتنا</h2>
      </div>
      <div className="row">
      {features.map((d, i) => (
            <div key={`${d.title}-${i}`} className={`col-md-4 mb-4 text-center wow animate__fadeInDown`}  data-wow-offset="100" data-wow-duration={`${2 + i}s`}>
              <div className="feature-item">
                <i id="fet" className={d.icon} style={{ background: "linear-gradient(to right, #6372ff 0%, #5ca9fb 100%)", display: "bluck" , padding:"40px", borderReadus:"50px" }}></i>
                <h3>{d.title}</h3>
                <p className="text-center">{d.text}</p>
              </div>
            </div>
          ))
        }
    </div>
    </div>
  </div>
  );
};
