import axios from "axios";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams ,useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { MDBBadge, MDBIcon } from "mdb-react-ui-kit";
import useUser from "../../hooks/useUser";
import Login from "../Login";

const CompanyMessageBox = () => {
  const [orders, setOrders] = useState([]);
  const { cutomerId } = useParams();
  const { user, isLoading } = useUser();
  const [notReadOrNum , setNotReadOrNum] =  useState(0) 
  const navigate =  useNavigate()
  const [companyProfile,setCompanyProfile] = useState([])

  useEffect(() => {
    const getOrders = async () => {
      try {
        const token = await user.getIdToken();
        const headers = token ? { authtoken: token } : {};

        const resp = await axios.get(`/api/companydashboard/messagebox`,{headers});
        setOrders(resp.data);
        
         
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    if (!isLoading && user) {
      getOrders();
    }
  }, [isLoading]);


  if(!isLoading){
    if(!user)
    return(
      <Login />
    )
  }

  


  return (
    <Container>
    <Row>
    <Col md={2}></Col>
    <Col md={8}>
      {orders.map((order, index) =>
          <Link
            to={{
              pathname: `/companydashboard/orderlist/orderdetails/${btoa(
                JSON.stringify(order._id)
              )}`
            }}
          >
            <Card className="my-3">
              <CardBody>
                <CardTitle>
                  <Row>
                    <Col>
                      {order.customerName}
                    </Col>
                    <Col className="text-start">
                      <a href="#!" style={{ marginRight: "5px" }}>
                        <MDBIcon fas icon="envelope" size="lg" />
                        {order.messages.filter(
                          (message) =>
                            message.isReaded === false && message.resciverId
                        ).length !== 0  && (
                          <MDBBadge color =  "danger" notification pill>
                            {
                              order.messages.filter(
                                (message) =>
                                  message.isReaded === false &&
                                  message.resciverId
                              ).length 
                            }
                          </MDBBadge>
                        )}
                      </a>
                    </Col>
                  </Row>
                </CardTitle>
                <Row>
                  <Col sm={6}>
                    <CardText>
                      <strong>الطلب :</strong> {order.subService}
                    </CardText>
                  </Col>
                  <Col sm={6}>
                    <CardText>
                      <strong>حالة الطلب :</strong> {order.status}
                    </CardText>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Link>
        
      )}
      </Col>
      <Col md={2}></Col>
      </Row>
    </Container>
  );
};

export default CompanyMessageBox;
