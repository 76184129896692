import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Container,
  ProgressBar,
  Row,
  Col,
  Form,
  Button,
  Image,
} from "react-bootstrap";
import { Link, useLocation ,useNavigate} from 'react-router-dom';
import { Card, CardBody, CardTitle, CardText } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useUser from "../../hooks/useUser";
import {jwtDecode} from 'jwt-decode';
import NotFoundPage from "../NotFoundPage";

let variant = "";
const OrdersInfo = () => {
  const { order_Id } = useParams();
  const [orderDeatails, setOrderDetails] = useState([]);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const {user, isLoading} = useUser()
  const navigate =  useNavigate()
  const [checkIsAdmin, setCheckIsAdmin] = useState(false);

  const isAdmin = async () => {
    try {
      const idTokenResult = await user.getIdTokenResult();
      // Check if the user has admin privileges
      return !!idTokenResult.claims.admin;
    } catch (error) {
      console.error("Error getting ID token:", error);
      return false;
    }
  };

  const decodedData = JSON.parse(atob(order_Id)); 
   const orderId = decodedData

  const getOrder = async () => {
    try {
      const token = await user.getIdToken();
      const headers = token ? {authtoken:token} : {}

      const resp = await axios.post(`/api/dashboard/order/${orderId}`,null,{headers});
      const decodedToken = jwtDecode(resp.data);
      setOrderDetails(decodedToken);

    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  useEffect(() => {
    if (!isLoading && user) {
        isAdmin().then((isadmin) => {
           if (isadmin) {
             setCheckIsAdmin(true);
             getOrder();
           } else {
             setCheckIsAdmin(false);
           }
         });
       }
  }, [order_Id,isLoading]);

 

 

  if(!isLoading){
    if(!user)
    return(
      navigate('/customerlogin')
    )
  }

  if (!checkIsAdmin && !isLoading) {
    return <NotFoundPage />;
  }


  return (
    <Container className="mt-4" style={{ widt: "70%" }}>
      <Card>
        <CardBody>
          <CardTitle><h4>{orderDeatails.customerName}</h4></CardTitle>
          <Row>
            <Col sm={6}>
              <CardText className="mt-3">
                <strong>الطلب : </strong> {orderDeatails.subService}
              </CardText>
              <CardText className="mt-3">
                <strong>الايميل :</strong> {orderDeatails.email}
              </CardText>
              <CardText className="mt-3">
                <strong>رقم الهاتف :</strong> {orderDeatails.phoneNumber}
              </CardText>
              <CardText className="mt-3">
                <strong>حالة الطلب :</strong> {orderDeatails.status}
              </CardText>
              <CardText className="mt-3">
              <strong>سعر مزود الخدمة :</strong> {orderDeatails.CompanyPrice}
            </CardText>
            <CardText>
            <strong>تاريخ نشر الطلب :</strong> {orderDeatails.orderDate}
          </CardText>
        
            </Col>
            <Col sm={6}>
              <CardText className="mt-3">
                <strong>المدينة :</strong> {orderDeatails.city}
              </CardText>
              <CardText>
                <strong>التاريخ:</strong> {orderDeatails.date}
              </CardText>
              <CardText>
                <strong>الوقت:</strong> {orderDeatails.time}
              </CardText>
              <CardText>
                <strong> طريقة الدفع : </strong> {orderDeatails.paymentMethod}
              </CardText>
              <CardText>
                    <strong> سعر العميل  : </strong>{" "}
                    {orderDeatails.cutomerPrice ? orderDeatails.cutomerPrice : "لم يحدد  سعر و اعتمد سعر مزود الخدمة"}
                  </CardText>
              {orderDeatails.status === "جديدة" && ( 
                <div className="alert-info" role="alert">
                  <CardText>
                      <strong>
                        {" "}
                       في انتظار موافقة أحد مزودي الخدمات  
                      </strong>
                  </CardText>
                  </div>
              )}
              {orderDeatails.status === "استفسارات" && (
                <div>
                <div className="alert-danger" role="alert">
                  <CardText>
                    {!orderDeatails.cusConfirmation && (
                      <strong>
                        {" "}
                       الطلب قيد الاستفسار و  النقاش
                      </strong>
                    ) }

                  </CardText>
                  </div>
                  <div className="alert-success" role="alert">
                  <CardText>
                    {orderDeatails.cusConfirmation && (
                      <strong>
                        {" "}
                        لقد تم تأكيد الطلب بواسطة العميل بإنتظار مزود الخدمة ليبدأ التنفيذ
                      </strong>
                    ) }
                  </CardText>
                  </div>
                </div>
              )}
              {orderDeatails.status === "قيد التنفيذ" && (
                <div>
                  <strong> مستوى التقدم: </strong>
                  <ProgressBar
                    fade
                    now={orderDeatails.progress}
                    label={`${orderDeatails.progress}%`}
                    style={{ height: "30px" }}
                    variant={variant}
                  />
                </div>
              )}

              {orderDeatails.CompanyComplete && ( 
                <div className="alert-info" role="alert">
                  <CardText>
                      <strong>
                        {" "}
                      {orderDeatails.custConfComplete ? 'تم انجاز الخدمة':'تم تحديد الطلب مكتمل بواسطة مزود الخدمة بإنتظار تأكيد العميل '} 
                      </strong>
                  </CardText>
                  </div>
              )}

            </Col>
           
            {((orderDeatails.status === "مكتملة") && (orderDeatails.custConfComplete)) && (
              <div>
                <Button
                  disabled
                  variant="success"
                  type="submit"
                  className="w-100 mt-4"
                >
                  تم انجاز الخدمة
                </Button>
              </div>
            )}
            {((orderDeatails.status === "قيد التنفيذ") && (orderDeatails.CompanyComplete)) && (
              <div>
                <Button
                  disabled
                  variant="success"
                  type="submit"
                  className="w-100 mt-4"
                >
                  تأكيد انجاز الخدمة
                </Button>
              </div>
            )}
           
         
          </Row>
        </CardBody>
      </Card>
      <Row className="mt-4">

      {orderDeatails.status === 'مكتملة' || orderDeatails.status === 'قيد التنفيذ' ? (
        <Card style={{ boxShadow: "none" , marginBottom:"30px"}}>
          <CardBody>
            <CardTitle className="mb-3">:بدأ التنفيذ بواسطة</CardTitle>
            {orderDeatails.companyAccept &&
              <Link to={{
                pathname: `/dashboard/order/${order_Id}/messages`,
                search: `?id=${btoa(JSON.stringify(orderDeatails.companyImplemented._id))}`
              }} >
                <strong>{orderDeatails.companyImplemented.Name}</strong>
              </Link>
            }
          </CardBody>
        </Card>
      ) : null}

      {orderDeatails.status === 'استفسارات' || orderDeatails.status === 'قيد التنفيذ' || orderDeatails.status === 'مكتملة' ? (
        <Card style={{ boxShadow: "none" }}>
          <CardBody>
            <CardTitle className="mb-5">مزودي الخدمات الذين قبلوا الطلب</CardTitle>
            {orderDeatails.companyAccept && orderDeatails.companyAccept.map((company, i) => (
              <Link to={{
                pathname: `/dashboard/order/${order_Id}/messages`,
                search: `?id=${btoa(JSON.stringify(company.companyId))}`
              }} key={i}>
                تم القبول بواسطة <strong>{company.Name}</strong>
                <hr />
              </Link>
            ))}
          </CardBody>
        </Card>
      ) : <h3>لم يُقبل الطلب من أي مزود خدمة بعد</h3>}


        
      </Row>
    </Container>
  );
};

export default OrdersInfo;




