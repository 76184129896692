import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faServer}  from "@fortawesome/free-solid-svg-icons";
import{
  Container,
  Form,
  FormControl,
  Card,
  ListGroup,
  Col,
  Modal,
  Button,
  Row,
} from "react-bootstrap";
import axios from "axios";

const ServicesPage = () => {
  const [services, setServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [addserviceName, setAddserviceName] = useState("");
  const [errorService, setErrorService] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    const getServices = async () => {
      try {
        const resp = await axios.get("/api/admindashboard/services");
        const newServices = resp.data;

        setServices(newServices);
      } catch (error) {
        console.error("Error fetching Services:", error);
      }
    };

    getServices();
  }, [addserviceName]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredServices = services.filter((service) =>
    service.ServiceName?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  

  const toggleOpen = () => setBasicModal(!basicModal);
  const [basicModal, setBasicModal] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const AddServicesHandeler = () => {
   // console.log(serviceName);
    try {
      const resp = axios.post("/api/services/addservice", {
        ServiceName: addserviceName,
      });

    
    } catch (e) {
      setErrorService(e.message);
    }

    setAddserviceName("");
    handleClose();
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <h2>الخدمات </h2>
        </Col>
        <Col md={2}>
          <Button variant="primary" onClick={handleShow} className="w-100 mb-3">
            اضافة خدمة
          </Button>
        </Col>
      </Row>
      <Row>
        <Form className="mb-3">
          <FormControl
            type="text"
            placeholder="ابحث عن خدمة..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </Form>
      </Row>


      
      {filteredServices.map((service) => ( 
        <Card key={service._id} className="px-4 mt-2">
          <Card.Header>
    <FontAwesomeIcon icon={faServer} /> {service.ServiceName}
     </Card.Header>
     
        <Card.Body  >
        <ListGroup>
        {service.ServiceList.map((subService, index) => ( 
              <ListGroup.Item key={index}>
              {subService}
           </ListGroup.Item>
           ))}
       </ListGroup>
     </Card.Body>
    
       
    </Card>
        
      ))}
      

      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>اضافة خدمة جديدة</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>اسم الخدمة</Form.Label>
                <Form.Control
                  type="text"
                  value={addserviceName}
                  onChange={(e) => setAddserviceName(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="px-4 w-50"
              variant="primary"
              onClick={AddServicesHandeler}
            >
              اضافة
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              الغاء
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  );
};

export default ServicesPage;
