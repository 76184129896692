import axios from 'axios';
import { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, CardText, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OrderList = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const getOrders = async () => {
            try {
                const resp = await axios.get("/api/admindashboard/orders");
                const newOrders = resp.data;
                setOrders(newOrders);
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        }

        getOrders();
    }, []);

    return (
        <Container>
            {orders.map((order, index) => (
                <Link key={index} to={`/admindashboard/orderlist/${order._id}`}>
                    <Card className="my-3">
                        <CardBody>
                            <CardTitle>{order.customerName}</CardTitle>
                            <Row>
                                <Col sm={6}>
                                    <CardText>
                                        <strong>الايميل : </strong> {order.email}
                                    </CardText>
                                    <CardText>
                                        <strong>حالة الطلب : </strong> {order.status}
                                    </CardText>
                                </Col>
                                <Col sm={6}>
                                    <CardText>
                                        <strong>الطلب :</strong> {order.subService}
                                    </CardText>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Link>
            ))}
        </Container>
    );
}

export default OrderList;
