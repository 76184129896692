import React ,{useEffect} from "react";
import WOW from 'wowjs';

export const Team = (props) => {

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const ServiceProviderCard = ({ image, companyName, description }) => {
    return (
      <div className="card">
        <img src={image} className="card-img-top" alt={companyName} />
        <div className="card-body">
          <h1 className="card-title">{companyName}</h1>
          <p className="card-text">{description}</p>
        </div>
      </div>
    );
  };

  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="row text-center">
          <div className="col-md-12 col-md-offset-2 section-title" >
            <h2 className="wow animate__fadeInUp"  data-wow-offset="100" data-wow-duration={`${2}s`}>أبرز مزودي الخدمات</h2>
            <p  className="wow animate__fadeInDown"  data-wow-offset="100" data-wow-duration={`${2}s`}>
              تصفح أبرز مزودي الخدمات الذين يقدمون خدمات عالية الجودة ومتنوعة في
              مجالات البناء والهندسة والفحص. اختر من بين مجموعة متنوعة من
              الشركات والمكاتب الهندسية وشركات الفحص التي تلبي احتياجاتك بسهولة
              وسرعة
            </p>
          </div>
        </div>
        <div id="row" className="row">
        <div class="logos">
        <div class="logos-slide">
          <img src={ require('../assets/images/Logos/Component 24 – 1.png') } alt="logo"/>    
          <img src={ require('../assets/images/Logos/Component 25 – 1.png') } alt="logo"/>    
          <img src={ require('../assets/images/Logos/Component 26 – 1.png') } alt="logo"/>    
          <img src={ require('../assets/images/Logos/Component 27 – 1.png') } alt="logo"/>    
          <img src={ require('../assets/images/Logos/Component 28 – 1.png') } alt="logo"/>    
          <img src={ require('../assets/images/Logos/Component 29 – 1.png') } alt="logo"/>    
          <img src={ require('../assets/images/Logos/Component 30 – 1.png') } alt="logo"/>
          <img src={ require('../assets/images/Logos/Component 31 – 1.png') } alt="logo"/>
          <img src={ require('../assets/images/Logos/Component 33 – 1.png') } alt="logo"/>
          <img src={ require('../assets/images/Logos/Component 34 – 1.png') } alt="logo"/>
          <img src={ require('../assets/images/Logos/Component 35 – 1.png') } alt="logo"/>
          <img src={ require('../assets/images/Logos/Component 36 – 1.png') } alt="logo"/>
          <img src={ require('../assets/images/Logos/Component 39 – 2.png') } alt="logo"/>
        </div>
      </div>
          
        </div>
      </div>
    </div>
  );
};
