import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, FormControl, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import Styles from "../../css/AdminDashBoard.module.css";
import {
  faBox,
  faClipboardCheck,
  faClock,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import useUser from "../../hooks/useUser";
import { MDBSpinner, MDBBtn } from "mdb-react-ui-kit";
import { jwtDecode } from "jwt-decode";
import NotFoundPage from "../NotFoundPage";

const Reports = () => {
  const getCookie = (name) => {
    const cookieValue = document.cookie.match(
      `(^|;)\\s*${name}\\s*=\\s*([^;]+)`
    );
    return cookieValue ? cookieValue.pop() : "";
  };

  const [orders, setOrders] = useState([]);
  const { user, isLoading } = useUser();
  const [dateFrom, setDateFrom] = useState(
    getCookie("dateFrom")
      ? getCookie("dateFrom")
      : new Date().toISOString().slice(0, 10)
  );
  const [dateTo, setDateTo] = useState(
    getCookie("dateTo")
      ? getCookie("dateTo")
      : new Date().toISOString().slice(0, 10)
  );
  const [loading, setLoading] = useState(false);
  const [checkIsAdmin, setCheckIsAdmin] = useState(false);

  const isAdmin = async () => {
    try {
      const idTokenResult = await user.getIdTokenResult();
      // Check if the user has admin privileges
      return !!idTokenResult.claims.admin;
    } catch (error) {
      console.error("Error getting ID token:", error);
      return false;
    }
  };

  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };

  const setCookies = () => {
    setCookie("dateFrom", dateFrom, 1);
    setCookie("dateTo", dateTo, 1);
  };

  const handleDone = async () => {
    try {
      setLoading(true); // Set loading to true when starting data fetching
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

      const resp = await axios.post(
        `/api/dashboard/report`,
        { dateFrom, dateTo },
        { headers }
      );
      const decodedToken = jwtDecode(resp.data);
      setOrders(decodedToken.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false); // Set loading to false when done fetching data
    }
  };

  useEffect(() => {
    if (!isLoading && user) {
     isAdmin().then((isadmin) => {
        if (isadmin) {
          setCheckIsAdmin(true);
          handleDone();
        } else {
          setCheckIsAdmin(false);
        }
      });
    }
  }, [isLoading]);

  if (!checkIsAdmin && !isLoading) {
    return <NotFoundPage />;
  }

  return (
    <div>
      <div className="container">
        <Row>
          {" "}
          <h2>التقارير</h2>{" "}
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Form.Group as={Col} controlId="date">
              <Form.Label>من</Form.Label>
              <Form.Control
                type="date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Form.Group as={Col} controlId="date">
              <Form.Label>إلى</Form.Label>
              <Form.Control
                type="date"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={4}></Col>
          <Col lg={4}>
            <MDBBtn
              className="mt-4 w-100"
              onClick={handleDone}
              disabled={loading}
            >
              {loading ? (
                <MDBSpinner grow role="status" className="me-2" />
              ) : (
                "تجهيز"
              )}
            </MDBBtn>
          </Col>
          <Col lg={4}></Col>
        </Row>
        <Row className="mt-4">
        <div><h3> تقرير الفترة بين {dateFrom} و {dateTo} </h3></div>
          <Col md={3} className="text-center mt-3">
            <Link
            disabled={loading}
              onClick={() => setCookies()}
              to={`/dashboard/reports/allorders`}
            >
              <Card>
                <Card.Header>
                  <FontAwesomeIcon icon={faBox} /> عدد الطلبات الكلي
                </Card.Header>
                <Card.Body>
                  <h1>{orders.length ? orders.length : 0}</h1>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={3} className="text-center mt-3">
            <Link
              onClick={() => setCookies()}
              to={`/dashboard/reports/completeorders`}
            >
              <Card>
                <Card.Header>
                  <FontAwesomeIcon icon={faClipboardCheck} /> المكتملة
                </Card.Header>
                <Card.Body>
                  <h1>
                    {orders.length
                      ? orders.filter((order) => order.status === "مكتملة")
                          .length
                      : 0}
                  </h1>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={3} className="text-center mt-3">
            <Link
              onClick={() => setCookies()}
              to={`/dashboard/reports/progressorders`}
            >
              <Card>
                <Card.Header>
                  <FontAwesomeIcon icon={faClock} /> قيد التنفيذ
                </Card.Header>
                <Card.Body>
                  <h1>
                    {orders.length
                      ? orders.filter((order) => order.status === "قيد التنفيذ")
                          .length
                      : 0}
                  </h1>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={3} className="text-center mt-3">
            <Link
              onClick={() => setCookies()}
              to={`/dashboard/reports/infoorders`}
            >
              <Card>
                <Card.Header>
                  <FontAwesomeIcon icon={faInfo} /> قيد الاستفسار
                </Card.Header>
                <Card.Body>
                  <h1>
                    {orders.length
                      ? orders.filter((order) => order.status === "استفسار")
                          .length
                      : 0}
                  </h1>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>
      </div>

      
    </div>
  );
};

export default Reports;
