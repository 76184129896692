import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, ProgressBar, ListGroup ,Badge  } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox, faClipboardCheck, faClock, faEnvelope, faFileAlt, faInfo, faListAlt,faServer } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams ,useNavigate } from "react-router-dom";
import axios from "axios";
import useUser from '../../hooks/useUser';
import { MDBBadge, MDBIcon, MDBSpinner } from 'mdb-react-ui-kit';
import NotFoundPage from "../NotFoundPage";
import WaitingApprove from "../WaitingApproval";
import Login from '../Login';

const CompanyDashboard = () => {
  const [orders,setOrders] = useState([]);
  const [companyProfile,setCompanyProfile] = useState([])
  const [serviceList , setServiceList] = useState([])
  const [messNum , setMessNum] =  useState(0) 
  const [isConfermed , setIsConfermed] =  useState(true) 
const [_3orders , set_3orders] =  useState([]) 
const [_3serviceList , set_3serviceList] =  useState([]) 
  const {companyId} = useParams()
  const { user ,isLoading } = useUser();
  const navigate =  useNavigate()
  const [loading, setLoading] = useState(false);

  const getData = async ()=> {
    setLoading(true)
    const token = await user.getIdToken();
    const headers = token ? {authtoken:token} : {}

    const resp = await axios.get(`/api/companyuser` ,{headers})
    setLoading(false)
    
 if(resp.data.orders.length === 2){
  const [obj1 , obj2 ] = resp.data.orders
  const _3order = [obj1,obj2]
  set_3orders(_3order)
} else{
  const [obj1] = resp.data.orders
  const _3order = [obj1]
  set_3orders(_3order)
}

setOrders(resp.data.orders)
//get servicelist
const subserv = resp.data.serviceList.map((ele,i)=> ele.ServiceList)


console.log(subserv.length > 0)
if(subserv.length > 0)
 if(subserv[0].length === 2){
  const [obj1 , obj2 ] = subserv[0].map((ele,i )=> ele)
  const _3order = [obj1,obj2]
  set_3serviceList(_3order)
} else{
  const [obj1] = subserv[0].map((ele,i )=> ele)
  const _3order = [obj1]
  set_3serviceList(_3order)
}


if(subserv.length < 0)
setServiceList(subserv[0].map((ele,i )=> ele))
 
 setCompanyProfile(resp.data.serviceProviders)
 setIsConfermed(resp.data.serviceProviders.isConfermed)
 
 const numUnreadMessages = resp.data.orders
 .flatMap(order => order.messages.filter(message => message.isReaded === false && message.resciverId))
 .length;
 setMessNum(numUnreadMessages);
 
  }

  useEffect(()=>{
    if (!isLoading && user) 
         getData()
    
  },[isLoading])
 
  
  if(!isLoading){
    if(!user)
    return(
      <Login />
    )
  }
   
  if(!isConfermed){
    return(
      <WaitingApprove />
    )
  }
  if(companyProfile.length > 0 )
  if(companyProfile.type !== 'company'){
    return(
      <NotFoundPage />
    )
  }
  
  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: "300px" }}>
        {" "}
        <MDBSpinner
          style={{ width: "100px", height: "100px" }}
          grow
          role="status"
          className="me-2"
        />{" "}
      </div>
    );
  }

  return (
    <div className="container">
      <h1>لوحة التحكم</h1>
      <p>عرض معلومات حول طلبات الخدمة وحالة إنجازها.</p>

      <Row>
      <Col md={3} className="text-center mt-3">
      <Link to= {`/companydashboard/neworders`}>
        <Card>
          <Card.Header>
            <FontAwesomeIcon icon={faListAlt} /> الطلبات الجديدة
          </Card.Header>
          <Card.Body>
          {orders.length ? <Badge bg={`${orders.filter((order) => order.status === "جديدة").length ? "danger": "primary"}`}><h2>{ orders.filter((order) => order.status === "جديدة").length}</h2></Badge>:<h1>0</h1>}
          </Card.Body>
        </Card>
        </Link>
      </Col>

      <Col md={9} className="mt-3">
        <Link>
          <Card>
          <Link to={`/companydashboard/messagebox`}>
            <Card.Header>
            <Row>
            <Col>
            <FontAwesomeIcon icon={faEnvelope} />  الرسائل 
         </Col>
            <Col className="text-start">
            <a href='#!' style={{marginRight:"5px"}}>
        <MDBIcon fas icon='envelope' size='lg' />
        {messNum > 0 && (
          <MDBBadge color='danger' notification pill>
          {messNum}
        </MDBBadge>
  )}
       
      </a>
            </Col>
            </Row>
            
            </Card.Header>
            <Card.Body>
              <ListGroup>
             
              {_3orders.length < 0 ? (_3orders.map((order, i) => (
                order.messages
                  .filter(message => message.resciverId)
                  .slice(-2)
                  .map((message, idx) => (
                    <ListGroup.Item key={idx}>
                      {message.message}
                    </ListGroup.Item>
                  ))
              ))):<p>لا توجد رسائل</p>}
           
              </ListGroup>
            </Card.Body>
            </Link>
          </Card>
          </Link>
        </Col>

      </Row>

      <Row className="mt-4">
        <Col md={3} className="text-center mt-3">
        <Link to= {`/companyuser/orderlist`} >
          <Card>
            <Card.Header>
              <FontAwesomeIcon icon={faBox} /> عدد الطلبات الكلي
            </Card.Header>
            <Card.Body>
             <h1>{orders.length  ? orders.length : 0}</h1>
            </Card.Body>
          </Card>
          </Link>
        </Col>
        <Col md={3} className="text-center mt-3">
        <Link to= {`/companydashboard/completeorders`}>
          <Card>
            <Card.Header>
              <FontAwesomeIcon icon={faClipboardCheck} /> الطلبات المكتملة
            </Card.Header>
            <Card.Body>
              <h1>{ orders.length  ? orders.filter((order) => order.status === "مكتملة").length : 0 }</h1>
            </Card.Body>
          </Card>
          </Link>
        </Col>
        <Col md={3} className="text-center mt-3">
        <Link to= {`/companydashboard/inprogressorders`}>
          <Card>
            <Card.Header>
              <FontAwesomeIcon icon={faClock} /> الطلبات قيد التنفيذ
            </Card.Header>
            <Card.Body>
              <h1>{orders.length  ? orders.filter((order) => order.status === "قيد التنفيذ").length : 0}
              
              </h1>
            </Card.Body>
          </Card>
          </Link>
        </Col>
        <Col md={3} className="text-center mt-3">
        <Link to= {`/companydashboard/infoorders`}>
          <Card>
            <Card.Header>
              <FontAwesomeIcon icon={faInfo} /> الطلبات قيد الاستفسار
            </Card.Header>
            <Card.Body>
            <h1>{orders.length  ? orders.filter((order) => order.status === "استفسار").length : 0}</h1>
            </Card.Body>
          </Card>
          </Link>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={5} >
          <Card>
            <Card.Header>
              <FontAwesomeIcon icon={faFileAlt} /> الملف الشخصي
            </Card.Header>
            <Card.Body>
              <p>اسم المستخدم: {!isLoading  && user.displayName}</p>
              <p>البريد الإلكتروني: {!isLoading && user.email}</p>
              <Link to={'/profile'}>
              <Button variant="primary"> تعديل الملف الشخصي</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={7} className="mt-3">
        <Link>
          <Card>
          <Link to={`/companydashboard/services`}>
            <Card.Header>
              <FontAwesomeIcon icon={faServer} /> الخدمات
            </Card.Header>
            <Card.Body>
              <ListGroup>
              {(_3serviceList.length > 0) ? (
                _3serviceList.map((service, i) => (
                  <ListGroup.Item key={i}>
                    {service.SubService}  {service.price}
                  </ListGroup.Item>
                ))
              ) : (
                <p>لا يوجد لديك خدمات حاليا اضغط للاضافة.</p>
              )}
              
              </ListGroup>
            </Card.Body>
            </Link>
          </Card>
          </Link>
        </Col>
      </Row>
{/*
      <Row className="mt-4">
      <Col md={12}>
        <Card>
          <Card.Header>إحصائيات الطلبات خلال الأشهر السابقة</Card.Header>
          <Card.Body>
            <Row>
              {monthlyOrders.map((order) => (
                <Col key={order.month} md={4} className="mb-3">
                  <Card>
                    <Card.Body>
                      <h3>{order.month}</h3>
                      <p>الطلبات المكتملة: {order.completed}</p>
                      <p>الطلبات غير المكتملة: {order.incomplete}</p>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      </Col>
      
    </Row>
       */}     
    </div>
  );
};

export default CompanyDashboard;
