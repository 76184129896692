import { Container } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, FormControl, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import Styles from "../../css/AdminDashBoard.module.css";
import {
  faBox,
  faBuilding,
  faClipboardCheck,
  faClock,
  faInfo,
  faTruck,
  faUser,
  faUserSecret,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import useUser from "../../hooks/useUser";
import { MDBSpinner, MDBBtn } from "mdb-react-ui-kit";
import { jwtDecode } from "jwt-decode";
import NotFoundPage from "../NotFoundPage";

const UsersList = () => {
  const { user, isLoading } = useUser();
  const [checkIsAdmin, setCheckIsAdmin] = useState(false);

  const isAdmin = async () => {
    try {
      const idTokenResult = await user.getIdTokenResult();
      // Check if the user has admin privileges
      return !!idTokenResult.claims.admin;
    } catch (error) {
      console.error("Error getting ID token:", error);
      return false;
    }
  };

useEffect(() => {
    if (!isLoading && user) {
        isAdmin().then((isadmin) => {
           if (isadmin) {
             setCheckIsAdmin(true);
             
           } else {
             setCheckIsAdmin(false);
           }
         });
       }
},[isLoading])

  if (!checkIsAdmin && !isLoading) {
    return <NotFoundPage />;
  }


  return (
    <div>
      <div className="container">
        <Row>
          {" "}
          <h2>المستخدمين</h2>{" "}
        </Row>

        <Row className="mt-4">
          <Col md={4} className="text-center mt-3">
            <Link
              to={`/dashboard/userslist/companies`}
            >
              <Card>
                <Card.Body>
                  <Link to={`/dashboard/userslist/companies`}>
                    <FontAwesomeIcon icon={faBuilding} size="3x" />
                    <h4 className="mt-3"> الشركات</h4>
                  </Link>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={4} className="text-center mt-3">
            <Link
              to={`/dashboard/userslist/customers`}
            >
              <Card>
                <Card.Body>
                  <Link to={`/dashboard/userslist/customers`}>
                    <FontAwesomeIcon icon={faUser} size="3x" />
                    <h4 className="mt-3"> افراد</h4>
                  </Link>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={4} className="text-center mt-3">
            <Link
            to={`/dashboard/userslist/admins`}
            >
              <Card>
                <Card.Body>
                  <Link  to={`/dashboard/userslist/admins`}>
                    <FontAwesomeIcon icon={faUsers} size="3x" />
                    <h4 className="mt-3"> فريق المنصة</h4>
                  </Link>
                </Card.Body>
              </Card>
            </Link>
          </Col>
         
        </Row>
      </div>
    </div>
  );
};

export default UsersList;
