import React, { useState } from "react";
import { Form, Col, Row, Spinner } from "react-bootstrap";
import { MDBContainer, MDBCol, MDBRow, MDBBtn } from "mdb-react-ui-kit";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  updateProfile,
  updatePhoneNumber
} from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const CustomerSignUp = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [verPassword, setVerPassword] = useState("");
    const [userName, setUserName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [address, setAddress] = useState("");
    const [error, setError] = useState("");
    const [loading , setLoading] = useState(false);
    const [isVerificationSent, setIsVerificationSent] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false); // State to handle sending code spinner
    const [resendAvailable, setResendAvailable] = useState(false); // State to handle resend button availability
    const [verificationConfirmed, setVerificationConfirmed] = useState(false); // State to handle email verification confirmation
    const navigate = useNavigate();

    const signup = async (e) => {
     
      e.preventDefault();
      if (password === verPassword) {
        try {
          setLoading(true)
          const auth = getAuth();
          const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
          );
          setIsVerificationSent(true);
          await sendVerificationCode(auth.currentUser);

        const user = auth.currentUser;
        const token = await user.getIdToken();
        const headers = token ? {authtoken:token} : {}
        const resp = await axios.post('/api/adduser',
          {
            id:user.uid,
            name: userName,
            email: email,
            phoneNumber: phoneNumber,
            address: address,
            type:'customer',
            isConfermed:false,
            createdAt :new Date().toISOString().slice(0, 10)
          }
          ,{headers})
          setLoading(false);
        } catch (e) {
          setError("هذا البريد موجود من قبل");
        }
      } else {
        setError("كلمة المرور غير متطابقة");
      }
    };
  
    const sendVerificationCode = async (user) => {
      setIsSendingCode(true);
      try {
        await sendEmailVerification(user);
        setError('')
      } catch (e) {
        setError("لقد تجاوزت الحد المسموح به لإعادة ارسال الرمز الرجاء المحاولة لاحقاً");
      } finally {
        setIsSendingCode(false);
      }
    };
  
    const verifyCodeAndRegister = async (e) => {
      e.preventDefault();
      try {
        const auth = getAuth();
        await signInWithEmailAndPassword(auth, email, password);
        const user = auth.currentUser;
        await updateProfile(user, {
          displayName: userName,
          phoneNumber: phoneNumber
        });

        if (user.emailVerified) {
          navigate("/companydashboard");
          
        } else {
          setError("قم بتأكيد الحساب عن طريق الرابط المرسل في الايميل اولا")
        }
      } catch (e) {
        setError("البريد الالكتروني موجود من قبل");
      }
    };
  
    const resendVerificationCode = async () => {
      setIsSendingCode(true);
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        await sendVerificationCode(user);
        
      } catch (error) {
        setError("فشلت عملية إعادة إرسال رمز التحقق");
      } finally {
        setIsSendingCode(false);
        setResendAvailable(false);
      }
    };

    return (
      <MDBContainer className="p-3 my-5 h-custom">
        <MDBRow>
          <div className="d-lg-block text-center" style={{ height: "90px" }}>
          <h1> انشاء حساب مستخدم</h1>
          </div>
          <MDBCol col="10" md="6">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              className="img-fluid"
              alt="Sample image"
            />
          </MDBCol>
          <MDBCol col="4" md="6">
            <Form onSubmit={isVerificationSent ? verifyCodeAndRegister : signup}>
              <div className="mb-3">
                {error && (
                  <div className="alert alert-danger text-center">{error}</div>
                )}
                <Row className="mb-3">
                  <Col md={12} lg={12}>
                    <Form.Group as={Col} controlId="companyName">
                      <Form.Label>الاسم</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="ادخل اسمك "
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12} lg={12}>
                    <Form.Group controlId="customerEmail">
                      <Form.Label>البريد الالكتروني</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="ادخل البريد الالكتروني"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={12}>
                  <Form.Group controlId="customerPhone">
                    <Form.Label>رقم الهاتف</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="ادخل رقم الهاتف"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={12} lg={12}>
                  <Form.Group controlId="customerAddress">
                    <Form.Label>المدينة</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ادخل المدينة"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                  <Col md={12} lg={12}>
                    <Form.Group controlId="customerPassword" className="w-100">
                      <Form.Label>كلمة السر</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="ادخل كلمة السر"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={12} className="mt-3">
                    <Form.Group controlId="customerPassword" className="w-100">
                      <Form.Label>اعد كتابة كلمة السر</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="ادخل كلمة السر مرة اخرى"
                        value={verPassword}
                        onChange={(e) => setVerPassword(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={12} className="mt-3">
                    <Form.Group
                      as={Col}
                      controlId="confirmationCheckbox"
                      style={{ textAlign: "right" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Form.Check.Input
                          required
                          type="checkbox"
                          style={{ marginLeft: "10px", float: "right" }}
                        />
                        <Form.Check.Label style={{ marginBottom: "0" }}>
                          قرأت وأوافق على{" "}
                          <Link to={"/"}>شروط استخدام </Link> المنصة و
                          <Link to={"/"}> بيان الخصوصية</Link> 
                        </Form.Check.Label>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                {isVerificationSent && (
                  <div className="alert alert-success text-center">
                    {" "}
                    تم ارسال رابط التأكيد الى بريدك الالكتروني قم بتأكيد الحساب
                    من خلاله
                  </div>
                )}
              </div>
              <div className="text-end text-md-start mt-4 pt-2">
              {isVerificationSent ? (
                <MDBBtn
                  type="button"
                  className="mb-2 px-5 w-100 mt-2"
                  size="lg"
                  onClick={resendVerificationCode}
                >
                  {isSendingCode ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "إعادة إرسال رابط التحقق"
                  )}
                </MDBBtn>
              ) : null}
              

                <MDBBtn
                  type="submit"
                  className="mb-0 px-5 w-100"
                  size="lg"
                  
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : isVerificationSent ? (
                    "اكمال"
                  ) : (
                    "ارسال رابط التحقق"
                  )}
                </MDBBtn>
               
                <p className="small fw-bold mt-2 pt-1 mb-2 text-end">
                  لديك حساب؟{" "}
                  <Link to={"/login"} className="">
                    تسجيل دخول
                  </Link>
                </p>
              </div>
            </Form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  };
  
  export default CustomerSignUp;
