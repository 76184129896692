import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { ThemeProvider } from 'react-bootstrap';
import './App.css'
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import * as serviceWorker from './serviceWorker';

const firebaseConfig = {
  apiKey: "AIzaSyCHf1sAy7wB4CIGBw-lsaI4DaCXOXuLwNA",
  authDomain: "my-react-blog-191fa.firebaseapp.com",
  projectId: "my-react-blog-191fa",
  storageBucket: "my-react-blog-191fa.appspot.com",
  messagingSenderId: "821981323548",
  appId: "1:821981323548:web:f85ed9cf7518f6c4ba9ad9",
  measurementId: "G-JZZWK4GFWJ"
};

// Initialize Firebase
 initializeApp(firebaseConfig);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider dir="rtl">
  <App />
</ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
