import { Col, Container, Row, Form } from "react-bootstrap";
import React, { useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
} from "mdb-react-ui-kit";

const ContactUs = () => {
  const [customerName, setCustomerName] = useState('');
  const [sendTo, setSendTo] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(customerName);
  };

  return (
    <Container style={{ marginTop: "40px" }}>
      <Row>
        <Col lg={2}></Col>
        <Col lg={10}>
          <h1 className="mb-4">تواصل معنا </h1>
          <Row>
            <Col lg={8}>
              <MDBCard>
                <MDBCardBody>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group as={Col} controlId="customerName">
                      <Form.Label>الاسم</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="أدخل اسمك"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="customerName"
                      className="mt-4"
                    >
                      <Form.Label>رقم الهاتف</Form.Label>
                      <Form.Control
                        type=""
                        placeholder="أدخل رقم الهاتف"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      controlId="customerName"
                      className="mt-4"
                    >
                      <Form.Label>عنوان الرسالة</Form.Label>
                      <Form.Control
                        type=""
                        placeholder="أدخل عنوان الرسالة"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      controlId="customerName"
                      className="mt-4"
                    >
                      <Form.Label>الجهه</Form.Label>

                      <div style={{ position: "relative" }}>
                      <Form.Control
                        as="select"
                        value={sendTo}
                        onChange={(e) => setSendTo(e.target.value)}
                        required
                      >
                        <option>خدمة العملاء</option>
                        <option>الشكاوي و الاقتراحات</option>
                        <option>المكتب الرئيسي</option>
                        
                      </Form.Control>
                    </div>

                    </Form.Group>

                    <Form.Group
                      as={Col}
                      controlId="customerName"
                      className="mt-4"
                    >
                      <Form.Label>الرسالة</Form.Label>
                      <Form.Control as="textarea" style={{ height: "100px" }} />
                    </Form.Group>
                  </Form>
                  <MDBBtn className="w-25 mt-4" type="submit">
                    ارسال
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </Col>
            <Col lg={4}>
              <MDBCard>
                <MDBCardBody>
                <div className="contact-item">
                <h3 className="mb-5">معلومات الاتصال</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i>  الرياض , أسواق السدحان
                  </span>
                 
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> +966xxxxxxxx
                  </span>{" "}
                  
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fas fa-envelope"></i> info@company.com
                  </span>{" "}
                  
                </p>
              </div>

                </MDBCardBody>
              </MDBCard>
            </Col>
          </Row>
        </Col>
        <Col lg={2}></Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
