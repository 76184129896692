import axios from "axios";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Link, useParams ,useNavigate } from "react-router-dom";
import useUser from '../../hooks/useUser';
import Login from '../Login';
import WaitingApprove from "../WaitingApproval";

const CompanyCompleteOrders = () => {
  const [orders, setOrders] = useState([]);
  const { user ,isLoading } = useUser();
  const [companyProfile,setCompanyProfile] = useState([])
  const [isConfermed , setIsConfermed] =  useState(true) 

  const navigate =  useNavigate()

  const getOrders = async () => {
    try {
      const token = await user.getIdToken();
  const headers = token ? {authtoken:token} : {}

  const resp = await axios.get(`/api/companyuser` ,{headers})
      const newOrders = resp.data.orders;
      setCompanyProfile(resp.data.serviceProviders)
      setIsConfermed(resp.data.serviceProviders.isConfermed)

        const News = newOrders.filter(
          (order) => order.status === "مكتملة"
        );
        setOrders(News);
      
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    
    if(!isLoading) 
    getOrders();

  }, [isLoading]);

  if(!isLoading){
    if(!user)
    return(
      <Login />
    )
  }

  if(!isConfermed){
    return(
      <WaitingApprove />
    )
  }



  return (
    <Container>
      {orders.map((order, index) => (
        <Link key={index} to={{
          pathname: `/companydashboard/orderlist/orderdetails/${btoa(JSON.stringify(order._id))}`
        }}>
          <Card className="my-3">
            <CardBody>
              <CardTitle>{order.customerName}</CardTitle>
              <Row>
                <Col sm={3}>
                  <CardText>
                    <strong>الايميل : </strong> {order.email}
                  </CardText>
                </Col>
                <Col sm={3}>
                  <CardText>
                    <strong>الطلب :</strong> {order.subService}
                  </CardText>
                </Col>
                <Col sm={3}>
                  <CardText>
                    <strong>حالة الطلب :</strong> {order.status}
                  </CardText>
                </Col>
                <Col sm={3}>
                  <CardText>
                    <strong>تاريخ نشر الطلب :</strong> {order.orderDate}
                  </CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Link>
      ))}
    </Container>
  );
};

export default CompanyCompleteOrders;
