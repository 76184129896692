import axios from "axios";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { MDBBtn , MDBSpinner} from "mdb-react-ui-kit";
import useUser from "../../hooks/useUser";
import NotFoundPage from "../NotFoundPage";

const NewUsers = () => {
  const [newUsers, setNewUsers] = useState([]);
  const { user, isLoading } = useUser();
  const [companyProfile, setCompanyProfile] = useState([]);
  const [loading , setLoading ] = useState(false);
  const navigate = useNavigate();
  const [checkIsAdmin, setCheckIsAdmin] = useState(false);

  const isAdmin = async () => {
    try {
      const idTokenResult = await user.getIdTokenResult();
      // Check if the user has admin privileges
      return !!idTokenResult.claims.admin;
    } catch (error) {
      console.error("Error getting ID token:", error);
      return false;
    }
  };

  const getUsers = async () => {
    try {
      const token = await user.getIdToken();
      const headers = token ? { authtoken: token } : {};

      const resp = await axios.get(`/api/admin`, { headers });
      setNewUsers(resp.data.Users);
      setCompanyProfile(resp.data.serviceProviders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
 const handleAccept = async (id) => {
    const token = await user.getIdToken();
    const headers = token? { authtoken: token } : {};
    setLoading(true);
    const resp = await axios.put('/api/dashboard/acceptuser' ,{userId:id}, { headers });
   
    if (resp.status === 200) {
        setLoading(false)
        getUsers();
    }
 }
  useEffect(() => {
    if (!isLoading && user) {
     isAdmin().then((isadmin) => {
        if (isadmin) {
          setCheckIsAdmin(true);
          getUsers();
        } else {
          setCheckIsAdmin(false);
        }
      });
    }
  }, [isLoading]);

  if (!isLoading) {
    if (!user) return navigate("/login");
  }

  if (!checkIsAdmin && !isLoading) {
    return <NotFoundPage />;
  }

  return (
    <Container>
      {newUsers.map((user, index) => (
        <Card className="my-3">
          <CardBody>
            <CardTitle className="mb-4">
              <h2>{user.name} </h2>
            </CardTitle>
            <Row>
              <Col sm={12}  md={12} xs = {12} lg = {3} className="mt-4">
                <CardText>
                  <strong>الايميل : </strong> {user.email}
                </CardText>
              </Col>
              <Col sm={12} md={12} xs = {12} lg = {3} className="mt-4">
                <CardText>
                  <strong>رقم الهاتف :</strong> {user.phoneNumber}
                </CardText>
              </Col>
              <Col sm={12} md={12} xs = {12} lg = {3} className="mt-4">
                <CardText>
                  <strong>المدينة :</strong> {user.address}
                </CardText>
              </Col>
              <Col sm={12} md={12} xs = {12} lg = {3}  className="mt-4">
                <CardText>
                  <strong>النوع :</strong>{" "}
                  {user.type === "company" ? "شركة" : "عميل"}
                </CardText>
              </Col>
            </Row>
            <Row style={{ marginTop: "40px" }}>
              <Col lg ={5} md={5} sm ={5} xs ={5} className="text-start" >
                <MDBBtn onClick={()=> handleAccept(user.id)}
                color="success" className="w-50">
                {loading ? <MDBSpinner grow role="status" className="me-2" /> : 'موافق'}  
                </MDBBtn>
              </Col>
              <Col lg={1} md = {1} sm ={1}  xs ={1}></Col>
              <Col lg ={5} md={5} sm ={5} xs ={5} className="text-end">
                <MDBBtn color="danger" className="w-50 w-xs-75">
                  رفض
                </MDBBtn>
              </Col>
            </Row>
          </CardBody>
        </Card>
      ))}
    </Container>
  );
};

export default NewUsers;
