import React ,{useEffect} from "react";
import WOW from 'wowjs';

export const Header = (props) => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-md-offset-2 intro-text">
                <h1 className=" text-center wow animate__fadeIn"  data-wow-offset="100" data-wow-duration="1s">
                منصة مجال البناء
                  <span></span>
                </h1>
                <p className=" text-center wow animate__fadeIn"  data-wow-offset="100" data-wow-duration="1.5s">نافذتك نحو الخدمات الهندسية و خدمات العقارات</p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
